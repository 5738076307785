import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, innerWidth, innerPadding, innerDummyPadding, summerColor } from 'variables/_index'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

// 画像
import CloseHukidashiImage from 'assets/images/summer/course/close_hukidashi.webp'

export interface CoursePrimaryProps {
  readonly noProps?: string
}

type CoursePrimaryPropsClassName = CoursePrimaryProps & {
  readonly className: string
}

function Component({ className }: CoursePrimaryPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={classNames(className, 's-course-primary-point2')}>
      <div className="c-point-box">
        <div className="c-point-head">
          <p className="c-point-head__badge">Point 2</p>
          <h4 className="c-point-head__text">
            <span className="is-small">自宅で実験ができる</span>
            <br />
            わくわく理科実験講座
          </h4>
        </div>

        <p className="c-course-text">
          <span className="is-strong">実験キットが自宅に届くから、実際に見て、触って、学べる！</span>
          先生と一緒に、実験から考察まで行います。理科や実験が好きなお子さまにおススメです。スマホでの受講が可能です。
        </p>

        <div className="c-primary-point-boxes s-course-primary-point2__boxes">
          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">対象</span>
            </h5>
            <p className="c-primary-point-text">小学1年生～小学6年生</p>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">実験内容</span>
            </h5>

            <ul className="c-course-curriculums-list">
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学1年生～小学3年生</span>
                <span className="c-course-curriculums-list__item-contents">びっくり！ばねガエル</span>
              </li>
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学4年生～小学6年生</span>
                <span className="c-course-curriculums-list__item-contents">にじいろ光ファイバー</span>
              </li>
            </ul>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">日程・時間</span>
            </h5>
            <p className="c-primary-point-text">7/20(土)　11:00~12:10</p>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">料金</span>
            </h5>
            <p className="c-primary-point-text is-bold">4,400円</p>

            <ul className="c-primary-point-notes">
              <li className="c-primary-point-notes__item">&#8251;表示価格は税込みです。</li>
              <li className="c-primary-point-notes__item">&#8251;教材の料金を含みます。</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="c-overview-close">
        <p className="c-overview-close__text">最終申し込み締切日</p>
        <img className="c-overview-close__arrow" src={CloseHukidashiImage} alt="" width="205" height="6.395" decoding="async" loading="lazy" />
        <p className="c-overview-close__date">6/29(土)</p>

        <ul className="c-overview-close__notes">
          <li className="c-overview-close__notes-item">&#8251;満席になり次第、申し込みを締め切る場合があります。</li>
          <li className="c-overview-close__notes-item">&#8251;申し込み締切日は各日程によって異なります。</li>
        </ul>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CoursePrimaryPropsClassName>`
  background-color: #d1efff;
  padding: ${rem(70 / 2)} ${rem(40 / 2)} ${rem(80 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(86)};
    padding-bottom: ${rem(80)};
  }

  &.s-course-primary-point2 {
  }

  .s-course-primary-point2__boxes {
    margin-top: ${rem(40)};
  }

  .c-primary-point-notes {
    list-style: none;
    margin: ${rem(22 / 2)} auto 0;
    width: fit-content;

    @media ${breakpoints.lg} {
      display: flex;
      justify-content: center;
      gap: 0.5em;
      width: 100%;
      margin-top: ${rem(10)};
    }
  }

  .c-primary-point-notes__item {
    font-size: ${rem(22 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(14)};
    }
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const CoursePrimary: (props: CoursePrimaryProps) => JSX.Element = StyledComponent

export default CoursePrimary
