import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, color, fontFamily } from 'variables/_index'
import { Link } from 'gatsby'
import { ArrowRightKu } from 'components/icon'
import { StaticImage } from 'gatsby-plugin-image'

// 画像
import CtaFusenDocumentImg from 'assets/images/summer/contact/cta_fusen_document.png'

export interface ButtonDocumentProps {
  readonly href: string
  readonly id: string
}

type ButtonDocumentPropsClassName = ButtonDocumentProps & {
  readonly className: string
}

function Component({ className, id, href }: ButtonDocumentPropsClassName) {
  return (
    <Link id={id} className={className} to={href}>
      <div className="e-content">
        <span className="e-illust">
          <img src={CtaFusenDocumentImg} alt="" width="35.515" height="20" decoding="async" loading="lazy" />
        </span>
        <span className="e-text">資料請求はこちら</span>
        <div className="e-icon">
          <ArrowRightKu className="e-arrow" color="#fff" />
        </div>
      </div>
    </Link>
  )
}

const StyledComponent = styled(Component)<ButtonDocumentPropsClassName>`
  display: inline-block;
  border-radius: 200px;
  position: relative;
  text-decoration: none;
  font-weight: 700;

  > .e-content {
    display: flex;
    align-items: center;
    border-radius: 200px;
    border: 4px solid #fff;
    background-color: #ff8c00;
    color: #fff;
    align-items: center;
    width: ${rem(600 / 2)};
    height: ${rem(140 / 2)};
    padding: ${rem(42 / 2)} ${rem(30 / 2)} ${rem(42 / 2)} ${rem(70)};
    position: relative;
    z-index: 2;
    transition: background-color 0.3s ease 0s, color 0.3s ease 0s;
    cursor: pointer;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
      height: ${rem(100)};
      padding: ${rem(28)} ${rem(40)} ${rem(28)} ${rem(140)};
      border-width: 6px;
    }

    > .e-illust {
      width: ${rem(71.03 / 2)};
      position: absolute;
      left: ${rem(30 / 2)};
      top: 50%;
      margin-top: ${rem(-36 / 2 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(55.05)};
        left: ${rem(42)};
        margin-top: ${rem(-34 / 2)};
      }

      img {
        margin: 0;
      }
    }

    > .e-text {
      font-size: ${rem(36 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 700;
      letter-spacing: 0.1em;

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
      }
    }

    > .e-icon {
      flex-shrink: 0;
      margin-left: auto;

      svg {
        display: block;
        width: ${rem(5)};
        height: auto;

        @media ${breakpoints.lg} {
          width: ${rem(8)};
        }
      }
    }

    &:hover,
    &:focus {
      background-color: #effbff;
      color: ${color.main};

      > .e-icon {
        path {
          stroke: ${color.main};
        }
      }
    }
  }

  @media ${breakpoints.lg} {
  }
`

export const ButtonDocument: (props: ButtonDocumentProps) => JSX.Element = StyledComponent

export default ButtonDocument
