import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerSeasonWidth, fontFamily, summerColor } from 'variables/_index'

// 画像
import ProblemCheckImg from 'assets/images/summer/problem/problem-check.svg'
import ProblemArrowImg from 'assets/images/summer/problem/problem-arrow.svg'
import ProblemNoteImg from 'assets/images/summer/problem/problem-note.webp'
import ProblemBundokiImg from 'assets/images/summer/problem/problem-bundoki.webp'

export interface ProblemSectionProps {
  readonly noProps?: string
}

type ProblemSectionPropsClassName = ProblemSectionProps & {
  readonly className: string
}

function Component({ className }: ProblemSectionPropsClassName) {
  return (
    <div className={className}>
      <ul className="c-check-lists">
        <li className="c-check-list">テストの点数が下がってきて心配。</li>
        <li className="c-check-list">
          ２学期の学校の授業に
          <br className="is-pc-hidden" />
          ついていけるのかな…
        </li>
        <li className="c-check-list">どう勉強させたらいいのか、わからない。</li>
      </ul>
      <img className="c-result-arrow" src={ProblemArrowImg} alt="" width={37.37} height={63.55} decoding="async" loading="lazy" />
      <p className="c-result-box">
        Fitのオンライン夏期講習では
        <br />
        一人ひとりの苦手に合わせた
        <br />
        <span className="is-strong">総復習</span>で”わかる”が増える！
        <br />
        だから
        <br />
        <span className="is-strong">2学期で点数アップできる！</span>
        <img className="c-result-note" src={ProblemNoteImg} alt="" width={43.28} height={53.095} decoding="async" loading="lazy" />
        <img className="c-result-bundoki" src={ProblemBundokiImg} alt="" width={45} height={24} decoding="async" loading="lazy" />
      </p>
    </div>
  )
}

const StyledComponent = styled(Component)<ProblemSectionPropsClassName>`
  background: linear-gradient(to top, ${summerColor.fitMain} ${rem(90)}, #d1efff 0);
  padding-top: ${rem(38)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(80)};
    background: linear-gradient(to top, ${summerColor.fitMain} ${rem(152)}, #d1efff 0);
  }

  .c-check-lists {
    width: fit-content;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: ${rem(28 / 2)};
    list-style: none;

    @media ${breakpoints.lg} {
      gap: ${rem(16)};
    }
  }

  .c-check-list {
    margin: 0;
    font-weight: 700;
    font-size: ${rem(32 / 2)};
    line-height: calc(48 / 32);
    color: #0028be;
    padding: 0 0 0 ${rem(18)};
    position: relative;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      padding-left: ${rem(34)};
    }

    &::before {
      content: '';
      background: url(${ProblemCheckImg}) no-repeat center center/contain;
      width: ${rem(25.28 / 2)};
      height: ${rem(25.99 / 2)};
      position: absolute;
      top: ${rem(5)};
      left: 0;

      @media ${breakpoints.lg} {
        width: ${rem(20.98)};
        height: ${rem(21.56)};
        top: ${rem(10)};
      }
    }

    .is-pc-hidden {
      @media ${breakpoints.lg} {
        display: none;
      }
    }
  }

  .c-result-arrow {
    width: ${rem(63.55 / 2)};
    display: block;
    margin: ${rem(18)} auto 0;

    @media ${breakpoints.lg} {
      margin-top: ${rem(26)};
      width: ${rem(50.84)};
    }
  }

  .c-result-box {
    margin: ${rem(18)} auto 0;
    padding: ${rem(20)} ${rem(20)} ${rem(15)};
    text-align: center;
    width: ${rem(633 / 2)};
    border-radius: ${rem(10 / 2)};
    background: #e6f9ff;
    border: ${rem(3 / 2)} solid #fff;
    font-weight: 700;
    font-size: ${rem(32 / 2)};
    line-height: 1.7;
    color: #0028be;
    position: relative;

    @media ${breakpoints.lg} {
      margin-top: ${rem(34)};
      padding: ${rem(26)} ${rem(32)} ${rem(24)};
      width: ${rem(508)};
      border-radius: ${rem(10)};
      border-width: ${rem(3)};
      font-size: ${rem(24)};
      line-height: 1.9;
    }

    .is-strong {
      font-size: ${rem(38 / 2)};
      background: #fff040;

      @media ${breakpoints.lg} {
        font-size: ${rem(30)};
      }
    }
  }

  .c-result-note {
    position: absolute;
    top: ${rem(36)};
    left: ${rem(-22)};
    width: ${rem(119.61 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      top: ${rem(56)};
      left: ${rem(-35)};
      width: ${rem(95.56)};
    }
  }

  .c-result-bundoki {
    position: absolute;
    top: ${rem(12)};
    right: ${rem(-10)};
    width: ${rem(90 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      top: ${rem(18)};
      right: ${rem(-18)};
      width: ${rem(71.91)};
    }
  }
`

export const ProblemSection: (props: ProblemSectionProps) => JSX.Element = StyledComponent

export default ProblemSection
