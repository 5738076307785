import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, summerColor, fontFamily, breakpoints, innerWidth } from 'variables/_index'
import { useInView } from 'react-intersection-observer'

import classNames from 'classnames'

// 画像
import ClockImage from 'assets/images/summer/course/ill_clock.webp'
import NoteImage from 'assets/images/summer/course/ill_note.webp'
import CheckImage from 'assets/images/summer/course/overview-check.webp'
import CrossImage from 'assets/images/summer/course/overview-cross.svg'
import ExampleImage from 'assets/images/summer/course/overview-example.svg'
import CloseHukidashiImage from 'assets/images/summer/course/close_hukidashi.webp'

export interface CourseJuniorHighSchoolProps {
  readonly noProps?: string
}

type CourseJuniorHighSchoolPropsClassName = CourseJuniorHighSchoolProps & {
  readonly className: string
}

function Component({ className }: CourseJuniorHighSchoolPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: ref2, inView: inView2 } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  return (
    <div id="junior-overview" className={classNames(className, 's-course-junior-high-school-overview')}>
      <h2 ref={ref2} className="c-overview-head" data-anim={inView2}>
        <img className="c-overview-head__ill1" src={ClockImage} alt="" width={44} height={42} decoding="async" loading="lazy" />
        受講概要
        <img className="c-overview-head__ill2" src={NoteImage} alt="" width={92.97} height={64.345} decoding="async" loading="lazy" />
      </h2>

      <div className="c-overview-items s-course-junior-high-school-overview__items">
        <div className="c-overview-item">
          <h3 className="c-overview-item__head">日程と時間割</h3>

          <div className="c-overview-item__contents">
            <p className="c-overview-item__lead">
              <span className="is-strong">❶日程・❷時間割・❸教科</span>
              の中から、<span className="is-underline">自由にスケジュールを選んで受講</span>できます。
            </p>

            <div className="c-overview-item__schedule">
              <h4 className="c-overview-schedule-head">
                <span className="is-strong">❶日程</span>を選ぶ
              </h4>

              <ul className="c-overview-schedule-list">
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  A日程｜7/15(月)~7/19(金)
                </li>
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  B日程｜7/22(月)~7/26(金)
                </li>
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  C日程｜7/29(月)~8/2(金)
                </li>
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  D日程｜8/5(月)~8/9(金)
                </li>
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  E日程｜8/19(月)~8/23(金)
                </li>
              </ul>
            </div>

            <img className="c-overview-item__arrow" src={CrossImage} alt="" width={15} height={15} decoding="async" loading="lazy" />

            <div className="c-overview-item__timetable">
              <h4 className="c-overview-schedule-head">
                <span className="is-strong">❷時間割</span>を選ぶ
              </h4>

              <ul className="c-overview-timetable-list">
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  1時間目｜14：30〜15：50
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  2時間目｜16：00〜17：20
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  3時間目｜17：30〜18：50
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  4時間目｜19：00〜20：20
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  5時間目｜20：30〜21：50
                </li>
              </ul>
            </div>

            <img className="c-overview-item__arrow" src={CrossImage} alt="" width={15} height={15} decoding="async" loading="lazy" />

            <div className="c-overview-item__subject">
              <h4 className="c-overview-schedule-head">
                <span className="is-strong">❸教科</span>を選ぶ
              </h4>

              <ul className="c-overview-subject-list">
                <li className="c-overview-subject-list__item">
                  <img
                    className="c-overview-subject-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  数学
                </li>
                <li className="c-overview-subject-list__item">
                  <img
                    className="c-overview-subject-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  英語
                </li>
                <li className="c-overview-subject-list__item">
                  <img
                    className="c-overview-subject-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  国語
                </li>
                <li className="c-overview-subject-list__item">
                  <img
                    className="c-overview-subject-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  理科
                </li>
                <li className="c-overview-subject-list__item">
                  <img
                    className="c-overview-subject-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  社会
                </li>
              </ul>
            </div>

            <div className="c-overview-example">
              <h5 className="c-overview-example__head">受講例</h5>

              <span className="c-overview-example__contents">
                <span className="c-overview-example__icon-text">
                  <img className="c-overview-example__icon" src={ExampleImage} alt="" width={35} height={20} decoding="async" loading="lazy" />
                  <p className="c-overview-example__text">中学2年生のAさんの場合・・・</p>
                </span>
                <p className="c-overview-example__text">
                  <span className="is-strong">A日程</span>の<span className="is-strong">3時間目</span>に<span className="is-strong">数学</span>
                  を受講しています。
                </p>
              </span>
            </div>
          </div>
        </div>

        <div className="c-overview-item">
          <h3 className="c-overview-item__head">授業形式</h3>

          <div className="c-overview-item__contents">
            <p className="c-format-text">1回｜80分授業</p>

            <ul className="c-format-list">
              <li className="c-format-list__item">数英国 ｜講師1人 対 生徒2人</li>
              <li className="c-format-list__item">理　社 ｜講師1人 対 生徒8人</li>
            </ul>
          </div>
        </div>

        <div className="c-overview-item">
          <h3 className="c-overview-item__head">料金</h3>

          <div className="c-overview-item__contents">
            <div className="c-price-lists">
              <div className="c-price-lists__item">
                <h4 className="c-price-lists__head">数英国</h4>
                <div className="c-price-lists__box">
                  <div className="c-price-lists__gakunen">中1・2</div>
                  <ul className="c-price-list">
                    <li className="c-price-list__item">
                      <span className="is-koma">5コマ</span>｜<span className="u-text-highlight-bold">無料</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">10コマ</span>｜<span className="u-text-bold">18,000円</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">15コマ</span>｜<span className="u-text-bold">36,000円</span>
                    </li>
                  </ul>
                </div>
                <div className="c-price-lists__box">
                  <div className="c-price-lists__gakunen">中3</div>
                  <ul className="c-price-list">
                    <li className="c-price-list__item">
                      <span className="is-koma">5コマ</span>｜<span className="u-text-highlight-bold">無料</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">10コマ</span>｜<span className="u-text-bold">19,500円</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">15コマ</span>｜<span className="u-text-bold">39,000円</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="c-price-lists__item">
                <h4 className="c-price-lists__head">理社</h4>
                <div className="c-price-lists__box">
                  <div className="c-price-lists__gakunen">中1・2</div>
                  <ul className="c-price-list">
                    <li className="c-price-list__item">
                      <span className="is-koma">5コマ</span>｜<span className="u-text-highlight-bold">無料</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">10コマ</span>｜<span className="u-text-bold">9,000円</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">15コマ</span>｜<span className="u-text-bold">18,000円</span>
                    </li>
                  </ul>
                </div>
                <div className="c-price-lists__box">
                  <div className="c-price-lists__gakunen">中3</div>
                  <ul className="c-price-list">
                    <li className="c-price-list__item">
                      <span className="is-koma">5コマ</span>｜<span className="u-text-highlight-bold">無料</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">10コマ</span>｜<span className="u-text-bold">12,000円</span>
                    </li>
                    <li className="c-price-list__item">
                      <span className="is-koma">15コマ</span>｜<span className="u-text-bold">24,000円</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <ul className="c-overview-item__notes">
              <li className="c-overview-item__notes-item">&#8251;表示価格は税込みです。</li>
              <li className="c-overview-item__notes-item">
                &#8251;テキスト料金は別途かかります。
                <br className="is-sp" />
                2,400円/冊　送料／2冊まで:800円 3冊以上:1,000円
              </li>
              <li className="c-overview-item__notes-item">&#8251;初めて受講される場合のみ無料で受講いただけます。</li>
            </ul>
          </div>
        </div>

        <div className="c-overview-item">
          <h3 className="c-overview-item__head">
            合宿<span className="is-small">（中3のみ）</span>
          </h3>

          <div className="c-overview-item__contents">
            <p className="c-overview-item__text">
              入試に向けての合宿をオンラインで実施。
              <br />
              普段手をつけづらい理科や社会を含めた5教科の過去問対策をまとめて行います。
            </p>

            <ul className="c-camp-list">
              <li className="c-camp-list__item">日程 ｜8/15(木)~8/17(土)</li>
              <li className="c-camp-list__item">
                料金 ｜<span className="u-text-bold">39,000円</span>
              </li>
            </ul>

            <ul className="c-overview-item__notes">
              <li className="c-overview-item__notes-item">&#8251;表示価格は税込みです。</li>
              <li className="c-overview-item__notes-item">
                &#8251;テキスト料金は別途かかります。
                <br className="is-sp" />
                1冊2,400円　送料／2冊まで：800円　3冊以上 ：1,000円
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="c-overview-close">
        <p className="c-overview-close__text">最終申し込み締切日</p>
        <img className="c-overview-close__arrow" src={CloseHukidashiImage} alt="" width={205} height={6.395} decoding="async" loading="lazy" />
        <p className="c-overview-close__date">8/8(木)</p>

        <ul className="c-overview-close__notes">
          <li className="c-overview-close__notes-item">&#8251;満席になり次第、申し込みを締め切る場合があります。</li>
          <li className="c-overview-close__notes-item">&#8251;申し込み締切日は各日程によって異なります。</li>
        </ul>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseJuniorHighSchoolPropsClassName>`
  padding: ${rem(100 / 2)} ${rem(40 / 2)} ${rem(101 / 2)};
  background-color: #d1efff;

  @media ${breakpoints.lg} {
    padding-top: ${rem(133.3)};
    padding-bottom: ${rem(80)};
  }

  /* s-course-junior-high-school-overview */
  &.s-course-junior-high-school-overview {
  }

  .s-course-junior-high-school-overview__items {
    margin-top: ${rem(52)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(88)};
    }
  }

  .s-course-high-school-overview__schedule {
    margin-top: ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(30)};
    }
  }

  .c-overview-item__text {
    margin: 0;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    line-height: calc(50 / 28);
    text-align: center;
    width: ${rem(300)};

    @media ${breakpoints.lg} {
      width: auto;
      margin-top: ${rem(15)};
      font-size: ${rem(18)};
      line-height: calc(32 / 18);
    }
  }

  .c-overview-subject-list {
    display: flex;
    justify-content: center;
    gap: ${rem(40 / 2)};
    border-bottom: solid #adadad ${rem(1)};
    margin: 0;

    @media ${breakpoints.lg} {
      gap: ${rem(44)};
    }
  }

  .c-overview-subject-list__item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: ${rem(18 / 2)} 0;
    font-size: ${rem(28 / 2)};
    word-break: keep-all;

    @media ${breakpoints.lg} {
      padding: ${rem(14)} 0;
      font-size: ${rem(18)};
    }
  }

  .c-overview-subject-list__item-icon {
    width: ${rem(19.93 / 2)};
    height: ${rem(18.65 / 2)};
    margin: 0 ${rem(10 / 2)} 0 0;

    @media ${breakpoints.lg} {
      width: ${rem(19)};
      height: ${rem(17.78)};
      margin: 0 ${rem(10)} 0 0;
    }
  }

  .c-camp-list {
    margin: ${rem(52 / 2)} auto 0;
    list-style: none;

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      display: flex;
      justify-content: space-between;
      gap: ${rem(55)};
      margin-top: ${rem(28)};
    }
  }

  .c-camp-list__item {
    margin: 0;
    padding: ${rem(16 / 2)} 0 ${rem(16 / 2)} ${rem(142 / 2)};
    border-bottom: solid #adadad ${rem(1)};
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      text-align: center;
      width: 100%;
      padding: ${rem(12)} 0;
      border-top: solid #adadad ${rem(1)};
      border-bottom: solid #adadad ${rem(1)};
      font-size: ${rem(18)};
    }

    &:first-child {
      border-top: solid #adadad ${rem(1)};
    }
  }

  .c-price-lists {
    margin: 0 auto;
    border-bottom: solid #adadad ${rem(0.5)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      display: flex;
      justify-content: center;
      width: ${rem(800)};
      gap: ${rem(60)};
      border-bottom: none;
    }
  }

  .c-price-lists__item {
    @media ${breakpoints.lg} {
      flex: 1;
      border-bottom: solid #adadad ${rem(1)};
    }
  }

  .c-price-lists__head {
    font-weight: 700;
    font-size: ${rem(32 / 2)};
    letter-spacing: 0.06em;
    text-align: center;
    color: #0093ff;
    background: #f2f2f2;
    padding: ${rem(12 / 2)};

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      padding: ${rem(12)};
    }
  }

  .c-price-lists__box {
    display: flex;
    align-items: center;
    border-bottom: solid #adadad ${rem(0.5)};

    @media ${breakpoints.lg} {
      border-bottom-width: ${rem(1)};
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .c-price-lists__gakunen {
    font-size: ${rem(14)};
    width: ${rem(88)};
    text-align: center;
    flex-shrink: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      width: ${rem(120)};
    }
  }

  .c-price-list {
    margin: 0;
    list-style: none;
    flex-grow: 1;

    @media ${breakpoints.lg} {
    }
  }

  .c-price-list__item {
    margin: 0;
    padding: ${rem(7)} 0 ${rem(7)} ${rem(40)};
    border-bottom: solid #adadad ${rem(0.5)};
    font-size: ${rem(14)};

    @media ${breakpoints.lg} {
      width: 100%;
      padding-top: ${rem(12)};
      padding-bottom: ${rem(12)};
      padding-left: ${rem(42)};
      border-bottom-width: ${rem(1)};
      font-size: ${rem(18)};
    }

    &:last-child {
      border-bottom: none;
    }

    .is-koma {
      display: inline-block;
      flex-shrink: 0;
      width: ${rem(50)};

      @media ${breakpoints.lg} {
        width: ${rem(65)};
      }
    }
  }
`

export const CourseJuniorHighSchool: (props: CourseJuniorHighSchoolProps) => JSX.Element = StyledComponent

export default CourseJuniorHighSchool
