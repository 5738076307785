import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, summerColor } from 'variables/_index'
import ContactButton from 'components/section/summer/atom/contactButton'
import DocumentButton from 'components/section/summer/atom/buttonDocument'
import { siteStructure } from 'utils/site'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

// 画像
import CtaElementaryImage from 'assets/images/summer/contact/cta_sho.png'
import CtaJuniorImage from 'assets/images/summer/contact/cta_chu.png'
import CtaHighImage from 'assets/images/summer/contact/cta_kou.png'
import CtaTextImage from 'assets/images/summer/contact/cta_text.webp'
import CtaText2Image from 'assets/images/summer/contact/cta_text2.webp'

export interface ContactSectionProps {
  readonly type: 'elementary' | 'junior' | 'high'
  readonly text?: boolean
  readonly text2?: boolean
  readonly isDocumentButton?: boolean
}

type ContactSectionPropsClassName = ContactSectionProps & {
  readonly className: string
}

function Component({ className, type, text, text2 = false, isDocumentButton = false }: ContactSectionPropsClassName) {
  const ctaImage =
    type === 'elementary' ? (
      <img src={CtaElementaryImage} width="300" height="131" decoding="async" loading="lazy" alt="夏期講習5日間を無料で体験" />
    ) : type === 'junior' ? (
      <img src={CtaJuniorImage} width="300" height="131" decoding="async" loading="lazy" alt="夏期講習5日間を無料で体験" />
    ) : (
      <img src={CtaHighImage} width="300" height="131" decoding="async" loading="lazy" alt="夏期講習5日間を無料で体験" />
    )

  return (
    <div className={className}>
      <div className={classNames('e-contact-area')}>
        <div className="e-inner">
          <div className="e-image">{ctaImage}</div>

          <div className="e-buttons">
            <div className="e-button">
              <ContactButton href={siteStructure.summerContact.path} />
            </div>
            {isDocumentButton && (
              <div className="e-button">
                <DocumentButton id="" href={siteStructure.pamphlet1.path} />
              </div>
            )}
          </div>

          {text && (
            <div className="e-text">
              <img
                src={CtaTextImage}
                alt="講習受講後に入会する場合入会金22,000円が無料！"
                width="294.75"
                height="56"
                decoding="async"
                loading="lazy"
              />
            </div>
          )}

          {text2 && (
            <div className="e-text2">
              <img
                src={CtaText2Image}
                alt="講習受講後の入会で入会金22,000円を全額免除！"
                width="319.75"
                height="56"
                decoding="async"
                loading="lazy"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactSectionPropsClassName>`
  font-weight: 700;
  text-align: center;
  position: relative;

  > .e-contact-area {
    background-color: #ffe000;
    padding-top: ${rem(40 / 2)};
    padding-bottom: ${rem(60 / 2)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(30)};
      padding-bottom: ${rem(46)};
    }

    > .e-inner {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      > .e-image {
        img {
          width: ${rem(600 / 2)};
          margin: 0;

          @media ${breakpoints.lg} {
            width: ${rem(500)};
          }
        }
      }

      > .e-buttons {
        margin-top: ${rem(10)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(6)};
        }

        > .e-button {
          text-align: center;

          &:nth-child(n + 2) {
            margin-top: ${rem(20 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(16)};
            }
          }
        }
      }

      > .e-text,
      > .e-text2 {
        padding: ${rem(48 / 2)} 0 0 0;
        text-align: center;

        @media ${breakpoints.lg} {
          padding-top: ${rem(31.3)};
        }

        img {
          margin: 0;
          width: ${rem(589.5 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(499.38)};
          }
        }
      }

      > .e-text2 {
        img {
          margin: 0;
          width: ${rem(639.5 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(501.38)};
          }
        }
      }
    }
  }
`

export const ContactSection: (props: ContactSectionProps) => JSX.Element = StyledComponent

export default ContactSection
