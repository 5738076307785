import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints } from 'variables/_index'
import ArrowCircleRight from 'components/icon/arrowCircleRight'
import { Link } from 'gatsby'

// 画像
import CtaFusenContactImg from 'assets/images/summer/contact/cta_fusen_contact.png'

export interface ContactButtonProps {
  readonly href: string
}

type ContactButtonPropsClassName = ContactButtonProps & {
  readonly className: string
}

function Component({ className, href }: ContactButtonPropsClassName) {
  return (
    <Link className={className} to={href}>
      <div className="e-content">
        <span className="e-illust">
          <img src={CtaFusenContactImg} alt="" width="35.515" height="20" decoding="async" loading="lazy" />
        </span>
        <span className="e-text">無料体験してみる</span>
        <div className="e-icon">
          <ArrowCircleRight color="#fff" />
        </div>
      </div>
    </Link>
  )
}

const StyledComponent = styled(Component)<ContactButtonPropsClassName>`
  display: inline-block;
  border-radius: ${rem(200)};
  position: relative;
  text-decoration: none;
  font-weight: 700;

  @keyframes ContactButtonSway {
    0% {
      transform: translate(${rem(-30)}, ${rem(4)}) rotate(18deg);
    }

    100% {
      transform: translate(${rem(8)}, ${rem(-8)}) rotate(-16deg);
    }
  }

  @keyframes ContactButtonSwayPC {
    0% {
      transform: translate(${rem(-30)}, ${rem(4)}) rotate(18deg);
    }

    100% {
      transform: translate(${rem(28)}, ${rem(-8)}) rotate(-16deg);
    }
  }

  > .e-content {
    display: flex;
    align-items: center;
    border-radius: ${rem(300)};
    border: ${rem(4)} solid #fff;
    background-color: #0028be;
    color: #fff;
    align-items: center;
    width: ${rem(600 / 2)};
    font-size: ${rem(18)};
    letter-spacing: 0.1em;
    padding: ${rem(18)} ${rem(60 / 2)} ${rem(18)} ${rem(70)};
    position: relative;
    z-index: 2;
    transition: all 0.3s ease 0s, color 0.3s ease 0s;
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
      padding: ${rem(28)} ${rem(40)} ${rem(28)} ${rem(140)};
      font-size: ${rem(24)};
      border-width: ${rem(6)};
    }

    > .e-illust {
      width: ${rem(71.03 / 2)};
      height: ${rem(40 / 2)};
      position: absolute;
      left: ${rem(30 / 2)};
      top: 50%;
      margin-top: ${rem(-29 / 2 / 2)};
      animation: ContactButtonSway 2.4s ease-in-out infinite alternate;

      img {
        margin: 0;
      }

      @media ${breakpoints.lg} {
        width: ${rem(49.72)};
        height: ${rem(28)};
        animation: ContactButtonSwayPC 3.4s ease-in-out infinite alternate;
      }
    }

    > .e-text {
    }

    > .e-icon {
      position: absolute;
      top: 50%;
      right: ${rem(40 / 2)};
      transform: translateY(-50%);
    }

    &:hover,
    &:focus {
      background-color: #effbff;
      color: #0093ff;
      border-color: #b9f0ff;

      > .e-icon {
        path {
          stroke: #0093ff;
        }

        g {
          fill: #effbff;
          stroke: #effbff;
        }
      }
    }
  }

  @media ${breakpoints.lg} {
  }
`

export const ContactButton: (props: ContactButtonProps) => JSX.Element = StyledComponent

export default ContactButton
