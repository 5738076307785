import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, summerColor, fontFamily, breakpoints, innerWidth } from 'variables/_index'
import { useInView } from 'react-intersection-observer'

// 画像
import IllustrationClockImage from 'assets/images/summer/course/ill_clock.png'
import IllustrationNoteImage from 'assets/images/summer/course/ill_note.png'
import CheckImage from 'assets/images/summer/course/overview-check.png'
import CrossImage from 'assets/images/summer/course/overview-cross.svg'
import ExampleImage from 'assets/images/summer/course/overview-example.svg'
import CloseHukidashiImage from 'assets/images/summer/course/close_hukidashi.png'
import classNames from 'classnames'

export interface CourseHighSchoolOverviewProps {
  readonly noProps?: string
}

type CourseHighSchoolOverviewPropsClassName = CourseHighSchoolOverviewProps & {
  readonly className: string
}

function Component({ className }: CourseHighSchoolOverviewPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: ref2, inView: inView2 } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  return (
    <div id="high-overview" className={classNames(className, 's-course-high-school-overview')}>
      <h2 ref={ref2} className="c-overview-head" data-anim={inView2}>
        <img className="c-overview-head__ill1" src={IllustrationClockImage} alt="" width={44} height={42} decoding="async" loading="lazy" />
        受講概要
        <img className="c-overview-head__ill2" src={IllustrationNoteImage} alt="" width={92.97} height={64.345} decoding="async" loading="lazy" />
      </h2>

      <div className="c-overview-items s-course-high-school-overview__items">
        <div className="c-overview-item">
          <h3 className="c-overview-item__head">日程と時間割</h3>

          <div className="c-overview-item__contents">
            <p className="c-overview-item__lead">
              <span className="is-strong">❶日程・❷時間割・❸教科</span>
              の中から、<span className="is-underline">自由にスケジュールを選んで受講</span>できます。
            </p>

            <div className="c-overview-item__schedule s-course-high-school-overview__schedule">
              <h4 className="c-overview-schedule-head">
                <span className="is-strong">❶日程</span>を選ぶ
              </h4>

              <p className="c-overview-item__text">以下の日程の中から、希望するコマ数を受講できます。</p>

              <ul className="c-overview-schedule-list">
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  英数｜7/22(月)~8/31(土)
                </li>
                <li className="c-overview-schedule-list__item">
                  <img
                    className="c-overview-schedule-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  理社｜8/5(月)~8/31(土)
                </li>
              </ul>
              <p className="c-overview-high-schedule-note">※日曜・夏期休業(8/11~14)は除く</p>
            </div>

            <img className="c-overview-item__arrow" src={CrossImage} alt="" width={15} height={15} decoding="async" loading="lazy" />

            <div className="c-overview-item__timetable">
              <h4 className="c-overview-schedule-head">
                <span className="is-strong">❷時間割</span>を選ぶ
              </h4>

              <ul className="c-overview-timetable-list">
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  1時間目｜14：30〜15：50
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  2時間目｜16：00〜17：20
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  3時間目｜17：30〜18：50
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  4時間目｜19：00〜20：20
                </li>
                <li className="c-overview-timetable-list__item">
                  <img
                    className="c-overview-timetable-list__item-icon"
                    src={CheckImage}
                    alt=""
                    width={9.965}
                    height={9.325}
                    decoding="async"
                    loading="lazy"
                  />
                  5時間目｜20：30〜21：50
                </li>
              </ul>
            </div>

            <img className="c-overview-item__arrow" src={CrossImage} alt="" width={15} height={15} decoding="async" loading="lazy" />

            <div className="c-overview-item__subject">
              <h4 className="c-overview-schedule-head">
                <span className="is-strong">❸教科</span>を選ぶ
              </h4>

              <div className="c-high-school-course-overview-subject-box">
                <p className="c-high-school-course-overview-subject-box__head">｜高校1年生｜</p>
                <p className="c-high-school-course-overview-subject-box__body">英語文法・数I・数 A・物理・化学・生物</p>
              </div>
              <div className="c-high-school-course-overview-subject-box">
                <p className="c-high-school-course-overview-subject-box__head">｜高校2年生｜</p>
                <p className="c-high-school-course-overview-subject-box__body">
                  英語文法・英語構文・数I・数 A・数II・ 数B・物理・化学・生物・歴史・地理
                </p>
              </div>
              <div className="c-high-school-course-overview-subject-box">
                <p className="c-high-school-course-overview-subject-box__head">｜高校3年生｜</p>
                <p className="c-high-school-course-overview-subject-box__body">
                  英語文法・英語構文・数I・数 A・数II・数B・ 数Ⅲ・数C・物理・化学・生物・歴史・地理
                </p>
              </div>
            </div>

            <div className="c-overview-example">
              <h5 className="c-overview-example__head">受講例</h5>

              <span className="c-overview-example__contents">
                <span className="c-overview-example__icon-text">
                  <img className="c-overview-example__icon" src={ExampleImage} alt="" width={35} height={20} decoding="async" loading="lazy" />
                  <p className="c-overview-example__text">高校1年生のBさんの場合・・・</p>
                </span>
                <p className="c-overview-example__text">
                  <span className="is-strong">7/22～7/26</span>の<span className="is-strong">3時間目</span>に
                  <br className="is-sp" />
                  <span className="is-strong">数学Ⅰ</span>を受講しています。
                </p>
              </span>
            </div>
          </div>
        </div>

        <div className="c-overview-item">
          <h3 className="c-overview-item__head">授業形式</h3>

          <div className="c-overview-item__contents">
            <div className="c-overview-item__inner">
              <p className="c-format-text">1回｜80分授業</p>

              <ul className="c-format-list">
                <li className="c-format-list__item">数　英 ｜講師1人 対 生徒2人</li>
                <li className="c-format-list__item">理　社 ｜講師1人 対 生徒5人</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="c-overview-item">
          <h3 className="c-overview-item__head">料金</h3>

          <div className="c-overview-item__contents">
            <div className="c-price-lists">
              <div className="c-price-lists__item">
                <h4 className="c-price-lists__head">数英</h4>
                <ul className="c-price-list">
                  <li className="c-price-list__item">
                    <span className="is-koma">5コマ</span>｜<span className="u-text-highlight-bold">無料</span>
                  </li>
                  <li className="c-price-list__item">
                    <span className="is-koma">10コマ</span>｜<span className="u-text-bold">22,000円</span>
                  </li>
                  <li className="c-price-list__item">
                    <span className="is-koma">15コマ</span>｜<span className="u-text-bold">44,000円</span>
                  </li>
                </ul>
              </div>
              <div className="c-price-lists__item">
                <h4 className="c-price-lists__head">理社</h4>
                <ul className="c-price-list">
                  <li className="c-price-list__item">
                    <span className="is-koma">5コマ</span>｜<span className="u-text-highlight-bold">無料</span>
                  </li>
                  <li className="c-price-list__item">
                    <span className="is-koma">10コマ</span>｜<span className="u-text-bold">13,000円</span>
                  </li>
                  <li className="c-price-list__item">
                    <span className="is-koma">15コマ</span>｜<span className="u-text-bold">26,000円</span>
                  </li>
                </ul>
              </div>
            </div>

            <ul className="c-overview-item__notes">
              <li className="c-overview-item__notes-item">&#8251;表示価格は税込みです。</li>
              <li className="c-overview-item__notes-item">
                &#8251;テキスト料金は別途かかります。
                <br className="is-sp" />
                2,400円〜/冊　送料／2冊まで:800円 3冊以上:1,000円
              </li>
              <li className="c-overview-item__notes-item">&#8251;初めて受講される場合のみ無料で受講いただけます。</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="c-overview-close">
        <p className="c-overview-close__text">最終申し込み締切日</p>
        <img className="c-overview-close__arrow" src={CloseHukidashiImage} alt="" width={205} height={6.395} decoding="async" loading="lazy" />
        <p className="c-overview-close__date">8/19(月)</p>

        <ul className="c-overview-close__notes">
          <li className="c-overview-close__notes-item">&#8251;満席になり次第、申し込みを締め切る場合があります。</li>
          <li className="c-overview-close__notes-item">&#8251;申し込み締切日は各日程によって異なります。</li>
        </ul>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseHighSchoolOverviewPropsClassName>`
  padding: ${rem(100 / 2)} ${rem(40 / 2)} ${rem(101 / 2)};
  background-color: #ddf3ff;

  @media ${breakpoints.lg} {
    padding: ${rem(133.3)} 0 ${rem(80)};
  }

  /* s-course-high-school-overview */
  &.s-course-high-school-overview {
  }

  .s-course-high-school-overview__items {
    margin-top: ${rem(52)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(87)};
    }
  }

  .s-course-high-school-overview__schedule {
    margin-top: ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
    }
  }

  .c-high-school-course-overview-subject-box {
  }

  .c-overview-high-schedule-note {
    font-size: ${rem(11)};
    margin: ${rem(5)} ${rem(0)} ${rem(0)} ${rem(0)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(14)};
      margin-top: ${rem(10)};
      margin-bottom: ${rem(0)};
      padding-left: ${rem(270)};
    }
  }

  .c-high-school-course-overview-subject-box__head {
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    text-align: center;
    color: #0093ff;
    padding: ${rem(8)};
    line-height: calc(48 / 28);
    border-bottom: ${rem(0.5)} solid #adadad;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      padding-top: ${rem(14)};
      padding-bottom: ${rem(12)};
      border-bottom-width: ${rem(1)};
    }
  }

  .c-high-school-course-overview-subject-box__body {
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    text-align: center;
    padding: ${rem(10)};
    line-height: calc(48 / 28);
    border-bottom: ${rem(0.5)} solid #adadad;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      padding-top: ${rem(15)};
      padding-bottom: ${rem(12)};
      border-bottom-width: ${rem(1)};
    }
  }

  .c-price-lists {
    margin: 0 auto;

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
      display: flex;
      justify-content: center;
      width: ${rem(800)};
      gap: ${rem(60)};
    }
  }

  .c-price-lists__item {
    @media ${breakpoints.lg} {
      flex: 1;
    }
  }

  .c-price-lists__head {
    font-weight: 700;
    font-size: ${rem(32 / 2)};
    letter-spacing: 0.06em;
    text-align: center;
    color: #0093ff;
    background: #f2f2f2;
    padding: ${rem(12 / 2)};

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      padding: ${rem(12)};
    }
  }

  .c-price-lists__box {
    display: flex;
    align-items: center;
    border-bottom: solid #adadad ${rem(0.5)};

    @media ${breakpoints.lg} {
      border-bottom-width: ${rem(1)};
    }
  }

  .c-price-lists__gakunen {
    font-size: ${rem(14)};
    width: ${rem(88)};
    text-align: center;
    flex-shrink: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
      width: ${rem(120)};
    }
  }

  .c-price-list {
    margin: 0;
    list-style: none;
    flex-grow: 1;

    @media ${breakpoints.lg} {
    }
  }

  .c-price-list__item {
    margin: 0;
    padding: ${rem(7)} 0 ${rem(7)} ${rem(90)};
    border-bottom: solid #adadad ${rem(0.5)};
    font-size: ${rem(14)};

    @media ${breakpoints.lg} {
      width: 100%;
      padding-top: ${rem(12)};
      padding-bottom: ${rem(12)};
      padding-left: ${rem(100)};
      border-bottom-width: ${rem(1)};
      font-size: ${rem(18)};
    }

    &:last-child {
      border-bottom: none;
    }

    .is-koma {
      display: inline-block;
      flex-shrink: 0;
      width: ${rem(50)};

      @media ${breakpoints.lg} {
        width: ${rem(65)};
      }
    }
  }
`

export const CourseHighSchoolOverview: (props: CourseHighSchoolOverviewProps) => JSX.Element = StyledComponent

export default CourseHighSchoolOverview
