import React from 'react'
import styled from 'styled-components'
import MainVisualSection from 'components/section/summer/mainVisualSection'
import ProblemSection from 'components/section/summer/problemSection'
import VoiceSection from 'components/section/summer/voiceSection'
import ContactSection from 'components/section/summer/contactSection'
import ContactCampaignSection from 'components/section/summer/contactCampaignSection'
import CourseSection from 'components/section/summer/courseSection'
import FlowSection from 'components/section/summer/flowSection'
import StyleSection from 'components/section/summer/styleSection'
import AboutSection from 'components/section/summer/aboutSection'
import { StaticImage } from 'gatsby-plugin-image'
import { breakpoints, rem } from 'variables/_index'

export interface SummerTemplateProps {
  readonly noProps?: string
}

type SummerTemplatePropsClassName = SummerTemplateProps & {
  readonly className: string
}

function Component({ className }: SummerTemplatePropsClassName) {
  return (
    <div className={className}>
      <MainVisualSection />
      <div className="e-announce">
        お申し込みは終了しました。
        <br />
        ※2025年夏期講習の詳細が決まり次第
        <br className="u-hidden-pc" />
        ページ更新いたします。
      </div>
      <ProblemSection />
      <VoiceSection />
      <ContactSection type="junior" text={false} />
      <AboutSection />
      <ContactCampaignSection type="junior" />
      <CourseSection />
      <FlowSection />
      <StyleSection />
      <ContactSection type="junior" text2={true} isDocumentButton={true} />
    </div>
  )
}

const StyledComponent = styled(Component)<SummerTemplatePropsClassName>`
  > .e-announce {
    position: relative;
    z-index: 20;
    margin: ${rem(0)} 0 ${rem(38)};
    line-height: 1.6;
    text-align: center;
    font-weight: bold;
    font-size: ${rem(18)};
    color: red;
    padding-left: ${rem(12)};
    padding-right: ${rem(12)};

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      margin-top: ${rem(0)};
      margin-bottom: ${rem(52)};
    }
  }
`

export const SummerTemplate: (props: SummerTemplateProps) => JSX.Element = StyledComponent

export default SummerTemplate
