import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints } from 'variables/_index'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

// 画像
import courseTabArrow from 'assets/images/summer/course/course_tab_arrow.svg'

export interface CourseTabProps {
  readonly gradeText: string
  readonly isCurrent?: boolean
  readonly onClick: () => void
}

type CourseTabPropsClassName = CourseTabProps & {
  readonly className: string
}

function Component({ className, gradeText, isCurrent = false, onClick }: CourseTabPropsClassName) {
  return (
    <div className={classNames(className, 'c-course-tab', isCurrent && 'is-current')} onClick={onClick}>
      <div className="c-course-tab__content">
        <p className="c-course-tab__title">
          {gradeText}
          <br className="is-sp" />
          コース
        </p>
        <div className="c-course-tab__arrow">
          <img src={courseTabArrow} alt="" width={12} height={20.42} decoding="async" loading="lazy" />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseTabPropsClassName>`
  cursor: pointer;
  border-radius: ${rem(10)} ${rem(10)} 0 0;
  position: relative;

  @media ${breakpoints.lg} {
    border-radius: ${rem(20)} ${rem(20)} 0 0;
  }

  .c-course-tab__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .c-course-tab__content {
    padding: ${rem(11)} ${rem(10 / 2)};
    border-radius: inherit;
    background-color: #e1ebf0;
    text-align: center;
    position: relative;
    border-bottom: none;
    z-index: -1;
    transition: background-color 0.3s ease 0s;
    cursor: pointer;
    border: ${rem(3 / 2)} solid #e1ebf0;

    @media ${breakpoints.lg} {
      border: ${rem(3)} solid #e1ebf0;
      padding-top: ${rem(32)};
      padding-bottom: ${rem(14)};
      border-width: 3px;
    }

    .c-course-tab__title {
      margin: 0;
      font-size: ${rem(36 / 2)};
      line-height: 1.1;
      letter-spacing: 0.1em;
      font-weight: bold;
      font-family: ${fontFamily.zenKaku};

      @media ${breakpoints.lg} {
        font-size: ${rem(28)};

        > .is-sp {
          display: none;
        }
      }
    }

    .c-course-tab__arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      margin: ${rem(16 / 2)} auto 0 auto;

      @media ${breakpoints.lg} {
        margin: ${rem(16)} auto 0 auto;
      }

      img {
        margin: 0;
        width: ${rem(20.42 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(20.42)};
        }
      }
    }
  }

  &.is-current {
    translate: 0 ${rem(2)};

    @media ${breakpoints.lg} {
      translate: 0 ${rem(3)};
    }

    .c-course-tab__arrow {
      img {
        width: ${rem(24.71 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(25)};
        }
      }
    }

    .c-course-tab__content {
      background-color: #d1efff;
      padding-top: ${rem(18)};
      border: ${rem(2)} solid #000000;
      border-bottom: none;
      z-index: 1;

      @media ${breakpoints.lg} {
        border-width: ${rem(3)};
        border-bottom: none;
        padding-top: ${rem(42)};
        padding-bottom: ${rem(20)};
      }

      .c-course-tab__title {
        font-size: ${rem(42 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
        }
      }
    }
  }

  @media ${breakpoints.lg} {
    &:hover {
      .c-course-tab__arrow {
        transform: translate(0, ${rem(3)});
      }
    }
  }
`

export const CourseTab: (props: CourseTabProps) => JSX.Element = StyledComponent

export default CourseTab
