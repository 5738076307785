import { StaticImage } from 'gatsby-plugin-image'
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerSeasonWidth, fontFamily, summerColor } from 'variables/_index'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import VoiceCard from 'components/section/summer/atom/voiceCard'
import { useInView } from 'react-intersection-observer'
import ArrowNextImage from 'assets/images/summer/voice/slide-right.svg'
import ArrowPrevImage from 'assets/images/summer/voice/slide-left.svg'
import ArrowNextImagePC from 'assets/images/summer/voice/slide-right-pc.svg'
import ArrowPrevImagePC from 'assets/images/summer/voice/slide-left-pc.svg'
import QuoteImage from 'assets/images/summer/voice/quote.svg'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/css'

export interface VoiceSectionProps {
  readonly noProps?: string
}

type VoiceSectionPropsClassName = VoiceSectionProps & {
  readonly className: string
}

function Component({ className }: VoiceSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-120px', // ref要素が現れてから120px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const splideRef = useRef<Splide>(null)
  const splideCurrentRef = useRef<HTMLSpanElement>(null)
  const splideTotalRef = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    if (splideRef.current?.splide && splideTotalRef.current && splideCurrentRef.current) {
      splideTotalRef.current.textContent = splideRef.current.splide.length.toString()
      splideRef.current.splide.on('active', function () {
        if (splideRef.current?.splide && splideCurrentRef.current) {
          splideCurrentRef.current.textContent = (splideRef.current.splide.index + 1).toString()
        }
      })
    }
  })

  return (
    <div className={className}>
      <h2 className="e-head" ref={ref} data-anim={inView}>
        <span className="e-text-line1">
          <span className="is-large">オンライン</span>で
        </span>
        <span className="e-text-line2">こんなに点数</span>
        <span className="e-text-line3">あがっています</span>
        <span className="e-head-arrow">
          <StaticImage src="../../../assets/images/spring/head-arrow.png" alt="" />
        </span>
      </h2>
      <div className="e-voice-area">
        <div className="e-voice-slider">
          <Splide
            ref={splideRef}
            options={{
              autoplay: true, // 自動再生を有効
              interval: 3000, // 自動再生の間隔を3秒に設定
              type: 'loop', // ループ再生を有効
              perPage: 2, // 1回のスライドで表示する枚数
              speed: 300, // スライドのアニメーション時間を0.3秒に設定
              perMove: 1, // 1枚ずつ移動
              focus: 0, // ページネーションを移動の枚数に合わせる
              gap: '2.5rem', // スライド間の余白を設定
              pagination: false,
              breakpoints: {
                740: {
                  perMove: 1,
                  perPage: 1,
                  padding: '3.75rem',
                  gap: '1.875rem',
                },
              },
            }}
          >
            <SplideSlide>
              <VoiceCard
                score="21"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student01.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="H.Sさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="23"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student02.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="Y.Tさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="32"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student03.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="K.Iさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="32"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student04.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="Y.Kさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="29"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student05.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="S.Tさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="36"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student06.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="K.Iさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="32"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student07.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="M.Dさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="29"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student08.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="S.Iさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="26"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student09.png" alt="" placeholder="none" />}
                grade="中学1年生"
                name="H.Hさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="24"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student10.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="R.Hさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="35"
                subject="英語"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student11.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="N.Oさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="53"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student12.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="K.Iさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="29"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student13.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="K.Nさん"
              />
            </SplideSlide>
            <SplideSlide>
              <VoiceCard
                score="54"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/summer/voice/student14.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="K.Kさん"
              />
            </SplideSlide>
          </Splide>
          <p className="c-splide-number-pagination">
            <span className="c-splide-number-pagination__current" ref={splideCurrentRef}>
              1
            </span>
            /
            <span className="c-splide-number-pagination__total" ref={splideTotalRef}>
              10
            </span>
          </p>
        </div>
      </div>
      <p className="e-attention">※点数アップ時の学年です。</p>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceSectionPropsClassName>`
  background-color: ${summerColor.fitMain};
  padding-top: ${rem(42)};
  padding-bottom: ${rem(22)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(88)};
    padding-bottom: ${rem(80)};
  }

  > .e-head {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    color: ${summerColor.accent2};

    &[data-anim='true'] {
      .e-text-line1,
      .e-text-line2,
      .e-text-line3 {
        animation: slideUp 0.8s 0s forwards;
      }

      > .e-head-arrow {
        animation: arrowSlide 1.5s 0.4s forwards;
      }
    }

    .e-head-arrow {
      position: absolute;
      width: ${rem(200)};
      right: ${rem(-75)};
      top: ${rem(45)};
      rotate: -45deg;
      opacity: 0;
      transform: translateX(${rem(-50)});

      @media ${breakpoints.lg} {
        width: ${rem(330)};
        right: ${rem(-120)};
        top: ${rem(75)};
      }
    }

    @keyframes arrowSlide {
      0% {
        opacity: 0;
        transform: translateX(${rem(-50)});
      }
      100% {
        transform: translateX(${rem(0)});
        opacity: 1;
      }
    }

    .e-text-line1 {
      position: relative;
      z-index: 2;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-size: ${rem(36 / 2)};
      letter-spacing: 0.05em;
      transform: translateY(${rem(25)});
      opacity: 0;
      color: #fff;

      @media ${breakpoints.lg} {
        font-size: ${rem(30)};
      }

      img {
        width: ${rem(323 / 2)};
        margin-right: ${rem(4)};

        @media ${breakpoints.lg} {
          width: ${rem(274.5)};
        }
      }

      .is-large {
        font-size: ${rem(46 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(42)};
        }
      }
    }

    .e-text-line2,
    .e-text-line3 {
      position: relative;
      z-index: 2;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
      border-radius: ${rem(10 / 2)};
      letter-spacing: 0.06em;
      font-size: ${rem(62 / 2)};
      padding: ${rem(3)} ${rem(22 / 2)};

      transform: translateY(${rem(25)});
      opacity: 0;

      @media ${breakpoints.lg} {
        border-radius: ${rem(10)};
        font-size: ${rem(52)};
        padding-top: ${rem(6)};
        padding-bottom: ${rem(6)};
        padding-left: ${rem(18)};
        padding-right: ${rem(18)};
      }
    }

    .e-text-line2 {
      margin-top: ${rem(24 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }

      &::before {
        content: '';
        position: absolute;
        width: ${rem(40 / 2)};
        height: ${rem(28 / 2)};
        background-image: url(${QuoteImage});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: ${rem(-14 / 2)};
        left: ${rem(-28 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(34)};
          height: ${rem(23.84)};
          top: ${rem(-17)};
          left: ${rem(-20)};
        }
      }
    }

    .e-text-line3 {
      margin-top: ${rem(10 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
      }
    }

    @keyframes slideUp {
      0% {
        transform: translateY(${rem(25)});
        opacity: 0;
      }
      100% {
        transform: translateY(${rem(0)});
        opacity: 1;
      }
    }
  }

  > .e-voice-area {
    margin-top: ${rem(42)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(90)};
    }

    > .e-voice-text {
      font-size: ${rem(44 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      color: #fff;
      text-align: center;
      line-height: 1.8;

      @media ${breakpoints.lg} {
        font-size: ${rem(38)};
      }

      .is-dot {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: ${rem(8 / 2)};
          height: ${rem(8 / 2)};
          background-color: currentColor;
          border-radius: 50%;
          top: ${rem(-1)};
          left: 50%;
          transform: translate(-50%);

          @media ${breakpoints.lg} {
            width: ${rem(6)};
            height: ${rem(6)};
            top: ${rem(0)};
          }
        }
      }

      .is-underline {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: ${rem(1)};
          background: currentColor;
          bottom: ${rem(0)};
          left: 50%;
          transform: translate(-50%);

          @media ${breakpoints.lg} {
            height: ${rem(2)};
          }
        }
      }
    }

    > .e-voice-slider {
      /* margin-top: ${rem(40 / 2)}; */

      @media ${breakpoints.lg} {
        width: ${rem(1000)};
        /* margin-top: ${rem(30)}; */
        margin-left: auto;
        margin-right: auto;
        padding-left: ${rem(30)};
        padding-right: ${rem(30)};
        overflow: hidden;
      }

      .swiper,
      .splide {
        padding-bottom: ${rem(14)};

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(40)};
          overflow: visible;
        }
      }

      .splide__track {
        overflow: visible;
      }

      .swiper-slide,
      .splide__slide {
        height: auto;

        > [class^='voiceCard'] {
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next,
      .splide__arrow--prev,
      .splide__arrow--next {
        width: ${rem(90 / 2)};
        height: ${rem(90 / 2)};
        margin-top: ${rem(-10)};
        opacity: 1;

        @media ${breakpoints.lg} {
          width: ${rem(60)};
          height: ${rem(60)};
          margin-top: ${rem(-20)};
        }

        svg {
          display: none;
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-prev,
      .splide__arrow--prev {
        background-image: url(${ArrowPrevImage});
        background-repeat: no-repeat;
        background-size: contain;
        left: auto;
        right: calc(50% + 32vw);

        @media ${breakpoints.lg} {
          background-image: url(${ArrowPrevImagePC});
          right: calc(50% + ${rem(440)});
        }
      }

      .swiper-button-next,
      .splide__arrow--next {
        background-image: url(${ArrowNextImage});
        background-repeat: no-repeat;
        background-size: contain;
        right: auto;
        left: calc(50% + 32vw);

        @media ${breakpoints.lg} {
          background-image: url(${ArrowNextImagePC});
          left: calc(50% + ${rem(440)});
        }
      }

      .swiper-pagination,
      .splide__pagination {
        bottom: ${rem(0)};
        font-size: ${rem(28 / 2)};
        font-weight: 700;
        color: #fff;
        letter-spacing: 0.06em;

        @media ${breakpoints.lg} {
          font-size: ${rem(16)};
        }
      }

      .swiper-pagination-bullet,
      .splide__pagination__page {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        background-color: #fff;
        opacity: 1;
        margin-right: ${rem(6)};
        margin-left: ${rem(6)};

        @media ${breakpoints.lg} {
          width: ${rem(12)};
          height: ${rem(12)};
          margin-right: ${rem(10)};
          margin-left: ${rem(10)};
        }

        &.swiper-pagination-bullet-active,
        &.is-active {
          background-color: ${summerColor.accent2};
        }
      }
    }
  }

  .e-attention {
    margin: ${rem(8)} 0 0;
    text-align: center;
    font-size: ${rem(11)};
    letter-spacing: 0.04em;
    color: #fff;

    @media ${breakpoints.lg} {
      margin-top: ${rem(18)};
      font-size: ${rem(14)};
      letter-spacing: 0.06em;
    }
  }

  .c-splide-number-pagination {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 0.5em;
    margin: 0;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    font-weight: 700;
    color: #fff;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }
  }
`

export const VoiceSection: (props: VoiceSectionProps) => JSX.Element = StyledComponent

export default VoiceSection
