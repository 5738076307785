import React, { useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerSeasonWidth, fontFamily } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import CourseTab from 'components/section/summer/atom/courseTab'
import CourseJuniorHighSchool from 'components/section/summer/courseJuniorHighSchool'
import CourseJuniorHighSchoolOverview from 'components/section/summer/courseJuniorHighSchoolOverview'
import CoursePrimaryPoint1 from 'components/section/summer/coursePrimaryPoint1'
import CoursePrimaryPoint2 from 'components/section/summer/coursePrimaryPoint2'
import CourseHighSchool from 'components/section/summer/courseHighSchool'
import CourseHighSchoolOverview from 'components/section/summer/courseHighSchoolOverview'
import ContactSection from 'components/section/summer/contactSection'

// 画像
import HeadingWaveImage from 'assets/images/summer/heading-wave.svg'

export interface CourseSectionProps {
  readonly noProps?: string
}

type CourseSectionPropsClassName = CourseSectionProps & {
  readonly className: string
}

function Component({ className }: CourseSectionPropsClassName) {
  const [currentTab, setCurrentTab] = useState<'primary' | 'junior-high-school' | 'high-school'>('junior-high-school')

  const handleTabClick = (selectTab: 'primary' | 'junior-high-school' | 'high-school') => {
    setCurrentTab(selectTab)
  }

  return (
    <div className={className}>
      <p className="c-course-lead">オンライン夏期講習</p>
      <h2 className="c-course-head">
        <span className="c-course-head__jp">選べるコース</span>
        <span className="c-course-head__en">course</span>
      </h2>

      <div className="c-course-items">
        <div className="c-course-item">
          <CourseTab gradeText="小学生" onClick={() => handleTabClick('primary')} isCurrent={currentTab === 'primary'} />
        </div>
        <div className="c-course-item">
          <CourseTab gradeText="中学生" onClick={() => handleTabClick('junior-high-school')} isCurrent={currentTab === 'junior-high-school'} />
        </div>
        <div className="c-course-item">
          <CourseTab gradeText="高校生" onClick={() => handleTabClick('high-school')} isCurrent={currentTab === 'high-school'} />
        </div>
      </div>
      <div className={classNames('c-tab-content', 'is-primary', currentTab === 'primary' && 'is-current')}>
        <CoursePrimaryPoint1 />
        <ContactSection type="elementary" text={false} />
        <CoursePrimaryPoint2 />
        <ContactSection type="elementary" text={false} />
      </div>
      <div className={classNames('c-tab-content', 'is-primary', currentTab === 'junior-high-school' && 'is-current')}>
        <CourseJuniorHighSchool />
        <ContactSection type="junior" text={false} />
        <CourseJuniorHighSchoolOverview />
        <ContactSection type="junior" text={true} />
      </div>
      <div className={classNames('c-tab-content', 'is-primary', currentTab === 'high-school' && 'is-current')}>
        <CourseHighSchool />
        <ContactSection type="high" text={false} />
        <CourseHighSchoolOverview />
        <ContactSection type="high" text={true} />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseSectionPropsClassName>`
  padding-top: ${rem(120 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(82)};
  }

  .c-course-lead {
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(48 / 2)};
    letter-spacing: 0.04em;
    text-align: center;
    color: #0028be;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(42)};
    }
  }

  .c-course-head {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .c-course-head__jp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${rem(68 / 2)};
    font-family: ${fontFamily.zenKaku};
    color: #0028be;

    @media ${breakpoints.lg} {
      font-size: ${rem(54)};
      font-weight: 700;
    }

    &::after {
      display: block;
      content: '';
      width: ${rem(120.12 / 2)};
      height: ${rem(12.55 / 2)};
      margin-top: ${rem(12)};
      background: url(${HeadingWaveImage}) no-repeat center / contain;

      @media ${breakpoints.lg} {
        width: ${rem(96.8)};
        height: ${rem(10.75)};
        margin-top: ${rem(10)};
        border-radius: ${rem(5)};
      }
    }
  }

  .c-course-head__en {
    font-family: ${fontFamily.zenKakuA};
    color: #acacac;
    font-size: ${rem(38 / 2)};
    margin-top: ${rem(8)};
    letter-spacing: 0.2em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(11.8)};
      font-size: ${rem(30)};
      font-weight: 600;
    }
  }

  .c-course-items {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: ${rem(18)};
    gap: ${rem(15 / 2)};

    @media ${breakpoints.lg} {
      gap: ${rem(25)};
      margin-top: ${rem(34)};
    }
  }

  .c-course-item {
    width: 100%;
    max-width: ${rem(210 / 2)};

    @media ${breakpoints.lg} {
      max-width: ${rem(310)};
    }
  }

  .c-tab-content {
    visibility: hidden;
    height: 0;
    overflow: hidden;

    &.is-current {
      visibility: visible;
      height: auto;
    }
  }

  .c-point-head {
    margin-bottom: ${rem(40 / 2)};

    @media ${breakpoints.lg} {
      margin-bottom: ${rem(40)};
    }
  }

  .c-point-head__badge {
    display: grid;
    align-items: center;
    align-items: center;
    font-size: ${rem(38 / 2)};
    margin: 0 auto;
    width: ${rem(280 / 2)};
    height: ${rem(48 / 2)};
    font-family: ${fontFamily.zenKaku};
    background-color: #0093ff;
    border-radius: ${rem(26 / 2)} ${rem(26 / 2)} 0 0;
    letter-spacing: 0.06em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: #fff;

    @media ${breakpoints.lg} {
      width: ${rem(224)};
      height: ${rem(42)};
      font-size: ${rem(30)};
      border-radius: ${rem(20)} ${rem(20)} 0 0;
    }

    &.is-second {
      margin-top: ${rem(60)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(120)};
      }
    }
  }

  .c-point-head__text {
    position: relative;
    margin: 0 auto;
    text-align: center;
    background-color: #ffee71;
    font-size: ${rem(42 / 2)};
    padding: ${rem(18)} 0 ${rem(30 / 2)} 0;
    font-family: ${fontFamily.zenKaku};
    border-top: solid #0093ff ${rem(1.5)};
    border-bottom: solid #0093ff ${rem(1.5)};
    line-height: calc(58 / 42);
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      width: ${rem(900)};
      font-size: ${rem(32)};
      padding: ${rem(27)} 0;
      line-height: calc(42 / 32);
      border-top-width: ${rem(3)};
      border-bottom-width: ${rem(3)};
      padding-top: ${rem(24)};
      padding-bottom: ${rem(24)};
    }

    .is-small {
      font-size: ${rem(36 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(28)};
      }
    }
  }

  .c-course-content-head {
    text-align: center;
    border-top: ${rem(1.5)} solid #000;
    border-bottom: ${rem(1.5)} solid #000;
    width: ${rem(370 / 2)};
    margin: 0 auto;
    padding: ${rem(5)} 0 ${rem(8)};
    font-size: ${rem(58 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      width: ${rem(293)};
      margin: ${rem(80)} auto 0 auto;
      padding: ${rem(18)} 0;
      border-top: ${rem(3)} solid #000;
      border-bottom: ${rem(3)} solid #000;
      font-size: ${rem(42)};
      margin-top: 0;
    }
  }

  .c-course-points {
    display: flex;
    flex-direction: column;
    gap: ${rem(30 / 2)};
    list-style: none;
    width: ${rem(560 / 2)};
    margin: ${rem(50 / 2)} auto 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(900)};
      margin-top: ${rem(58)};
      flex-direction: row;
      gap: ${rem(40)};
    }
  }

  .c-course-point {
    font-size: ${rem(36 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    line-height: calc(68 / 36);
    margin: 0;
    padding: ${rem(4)} ${rem(33 / 2)};
    border-radius: ${rem(10 / 2)};
    background-color: #ffee71;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: ${rem(24)};
      border-radius: ${rem(10)};
      padding-bottom: ${rem(8)};
    }

    .is-number {
      font-size: ${rem(52 / 2)};
      font-family: ${fontFamily.zenKakuA};
      font-weight: 700;
      line-height: calc(68 / 52);
      color: #0093ff;
      margin-right: ${rem(7 / 2)};
      letter-spacing: 0.1em;

      @media ${breakpoints.lg} {
        font-size: ${rem(42)};
        margin-right: ${rem(5 / 8)};
      }
    }
  }

  .c-point-box {
    background-color: #fff;
    border-radius: ${rem(20 / 2)};
    padding: ${rem(26)} ${rem(12)} ${rem(28)};

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
      margin-left: auto;
      margin-right: auto;
      padding: ${rem(55)} 0 ${rem(57)} 0;
      border-radius: ${rem(20)};
    }
  }

  .c-point-box__head {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-family: ${fontFamily.zenKakuA};
    font-size: ${rem(54 / 2)};
    text-align: center;
    text-decoration: underline;
    text-decoration-color: #000;
    text-decoration-thickness: ${rem(1.5)};
    text-underline-offset: ${rem(6)};
    letter-spacing: 0.04em;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(42)};
      letter-spacing: 0.06em;
      text-decoration-thickness: ${rem(3)};
      text-underline-offset: ${rem(14)};
    }

    &[data-anim='true'] {
      .c-point-box__illust1 {
        animation: slideFadeIn 1s 0s forwards;
      }

      .c-point-box__illust2 {
        animation: slideFadeIn 1s 0s forwards;
      }
    }

    .is-color {
      color: #0093ff;
    }

    .is-large {
      font-size: ${rem(68 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(54)};
      }
    }
  }

  .c-point-box__illust1 {
    position: absolute;
    width: ${rem(143.78 / 2)};
    top: ${rem(-50)};
    left: ${rem(-70)};
    opacity: 0;
    transform: translateY(${rem(20)});
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(108)};
      top: ${rem(-34)};
      left: ${rem(-140)};
    }
  }

  .c-point-box__illust2 {
    position: absolute;
    width: ${rem(50)};
    bottom: ${rem(8)};
    right: ${rem(-68)};
    opacity: 0;
    transform: translateY(${rem(20)});
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(90.1)};
      right: ${rem(-138)};
      bottom: ${rem(-8)};
    }
  }

  .c-course-text {
    width: ${rem(280)};
    font-size: ${rem(32 / 2)};
    letter-spacing: 0.06em;
    line-height: calc(58 / 32);
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(620)};
      font-size: ${rem(18)};
      line-height: calc(32 / 18);
    }

    .is-strong {
      color: #0093ff;
      font-weight: 700;
    }
  }

  .c-primary-point-boxes {
    display: flex;
    flex-direction: column;
    gap: ${rem(35)};

    @media ${breakpoints.lg} {
      gap: ${rem(60)};
    }
  }

  .c-primary-point-box {
  }

  .c-primary-point-head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${rem(620 / 2)};
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(900)};
    }

    &::before,
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: ${rem(2)};
      background-color: #000;

      @media ${breakpoints.lg} {
        height: ${rem(3)};
      }
    }
  }

  .c-primary-point-head__text {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: ${rem(300 / 2)};
    height: ${rem(64 / 2)};

    text-align: center;
    color: #0028be;
    font-size: ${rem(42 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;

    border: ${rem(1.5)} solid #000;
    border-radius: 9999px;
    background-color: #ffee71;

    @media ${breakpoints.lg} {
      min-width: ${rem(250)};
      height: ${rem(51)};
      font-size: ${rem(30)};
      border-width: ${rem(3)};
    }
  }

  .c-primary-point-text {
    text-align: center;
    margin: ${rem(32 / 2)} 0 0 0;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      margin: ${rem(22)} 0 0 0;
      font-size: ${rem(18)};
    }

    &.is-bold,
    .is-bold {
      font-weight: bold;
    }
  }

  .c-course-curriculums-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: ${rem(30 / 2)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(900)};
      flex-direction: row;
      gap: ${rem(60)};
      margin-top: ${rem(25)};

      &.is-three {
        gap: ${rem(30)};
      }
    }
  }

  .c-course-curriculums-list__item {
    display: flex;
    flex-direction: column;
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(380)};
    }

    &:not(:first-child) {
      margin-top: ${rem(25 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(0)};
      }
    }
  }

  .c-course-curriculums-list__item-head {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #0093ff;
    font-size: ${rem(28 / 2)};
    padding: ${rem(12 / 2)} 0;
    letter-spacing: 0.06em;
    background-color: #f2f2f2;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      padding: ${rem(12)} 0;
    }
  }

  .c-course-curriculums-list__item-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(28 / 2)};
    padding: ${rem(12 / 2)} 0;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      padding: ${rem(12)} 0;
    }

    &[data-type='price'] {
      justify-content: flex-start;
      padding-left: ${rem(184 / 2)};
      border-bottom: ${rem(1)} solid #adadad;

      @media ${breakpoints.lg} {
        padding-left: ${rem(92)};
      }
    }

    .is-bold {
      font-weight: bold;
    }
  }

  .c-sub-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: ${rem(8)};
    margin: ${rem(16)} auto 0;
    text-align: center;
    font-size: ${rem(36 / 2)};
    color: #0028be;
    font-family: ${fontFamily.zenKaku};
    text-decoration: underline;
    text-decoration-color: #ffee71;
    text-underline-offset: ${rem(3)};
    text-decoration-thickness: ${rem(8 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(27)};
      text-decoration-thickness: ${rem(6.4)};
      margin-top: ${rem(42)};
      gap: ${rem(10)};
    }
  }

  .c-sub-head__deco {
    position: relative;
    height: ${rem(60 / 2)};

    @media ${breakpoints.lg} {
      height: ${rem(48)};
    }

    &[data-type='left'] {
      border-left: solid #000 ${rem(2)};

      @media ${breakpoints.lg} {
        border-left: solid #000 ${rem(3)};
      }

      &::before,
      &::after {
        display: block;
        content: '';
        width: ${rem(18 / 2)};
        height: ${rem(2)};
        background-color: #000;
        border-radius: 0 9999px 9999px 0;

        @media ${breakpoints.lg} {
          width: ${rem(15)};
          height: ${rem(3)};
        }
      }

      &::before {
      }

      &::after {
        position: absolute;
        bottom: 0;
      }
    }
    &[data-type='right'] {
      border-right: solid #000 ${rem(2)};

      @media ${breakpoints.lg} {
        border-right: solid #000 ${rem(3)};
      }

      &::before,
      &::after {
        display: block;
        content: '';
        width: ${rem(18 / 2)};
        height: ${rem(2)};
        background-color: #000;
        border-radius: 9999px 0 0 9999px;

        @media ${breakpoints.lg} {
          width: ${rem(15)};
          height: ${rem(3)};
        }
      }

      &::before {
      }

      &::after {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .c-online-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${rem(28)};

    @media ${breakpoints.lg} {
      width: ${rem(900)};
      margin-left: auto;
      margin-right: auto;
      margin-top: ${rem(40)};
    }

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: ${rem(3 / 2)};
      background-color: #d6d6d6;

      @media ${breakpoints.lg} {
        height: ${rem(3)};
      }
    }
  }

  .c-online-top__image {
    flex-shrink: 0;
    margin: 0 ${rem(17 / 2)};

    @media ${breakpoints.lg} {
      margin: 0 ${rem(11.9)};
    }

    img {
      margin: 0;
      width: ${rem(82 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(57.4)};
      }
    }
  }

  .c-cycle-figure {
  }

  .c-cycle-figure__caption {
    margin: 0;
    text-align: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 500;
    font-size: ${rem(30 / 2)};
    letter-spacing: 0.15em;

    @media ${breakpoints.lg} {
      font-size: ${rem(21)};
    }
  }

  .c-cycle-figure__image {
    display: block;
    width: ${rem(510 / 2)};
    margin: ${rem(13.5 / 2)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(367.2)};
      margin: ${rem(11.4)} auto 0 auto;
    }
  }

  .c-cycle-step {
    /* width: ${rem(560 / 2)}; */

    /* @media ${breakpoints.lg} {
      width: ${rem(620)};
    } */
  }

  .c-cycle-step__arrow {
    display: block;
    width: ${rem(63.55 / 2)};
    margin: ${rem(25)} auto;

    @media ${breakpoints.lg} {
      width: ${rem(50.84)};
      margin-top: ${rem(36)};
      margin-bottom: ${rem(36)};
    }
  }

  .c-cycle-step__step1 {
  }

  .c-cycle-step__step2 {
  }

  .c-cycle-step__step3 {
  }

  .c-cycle-step__result {
    margin-top: ${rem(20)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }
  }

  .c-overview-head {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-size: ${rem(54 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    text-align: center;
    color: #0028be;
    letter-spacing: 0.06em;
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: ${rem(6)};
    text-decoration-thickness: ${rem(1.5)};

    @media ${breakpoints.lg} {
      font-size: ${rem(42)};
      text-decoration-thickness: ${rem(3)};
    }

    &[data-anim='true'] {
      .c-overview-head__ill1 {
        animation: slideFadeIn 1s 0s forwards;
      }

      .c-overview-head__ill2 {
        animation: slideFadeIn 1s 0s forwards;
      }
    }
  }

  .c-overview-head__ill1 {
    position: absolute;
    left: ${rem(-128 / 2)};
    bottom: 0;
    width: ${rem(88 / 2)};
    height: ${rem(84 / 2)};
    opacity: 0;
    transform: translateY(${rem(20)});
    margin: 0;

    @media ${breakpoints.lg} {
      left: ${rem(-90)};
      bottom: ${rem(-2)};
      width: ${rem(65.2)};
      height: ${rem(62.69)};
    }
  }

  .c-overview-head__ill2 {
    position: absolute;
    bottom: 0;
    width: ${rem(185.4 / 2)};
    height: ${rem(128.69 / 2)};
    opacity: 0;
    transform: translateY(${rem(20)});
    margin: 0;

    @media ${breakpoints.lg} {
      right: ${rem(-158)};
      bottom: ${rem(-2)};
      width: ${rem(148.75)};
      height: ${rem(102.95)};
    }
  }

  .c-overview-close {
    margin: ${rem(60 / 2)} auto 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(400)};
      margin: ${rem(60)} auto 0 auto;
    }
  }

  .c-overview-close__text {
    font-size: ${rem(36 / 2)};
    text-align: center;
    margin: 0;
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }
  }

  .c-overview-close__arrow {
    display: block;
    width: ${rem(410 / 2)};
    margin: ${rem(13.5 / 2)} auto 0;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(8)};
      width: ${rem(339.54)};
    }
  }

  .c-overview-close__date {
    font-size: ${rem(42 / 2)};
    text-align: center;
    margin: ${rem(12.7 / 2)} 0 0 0;
    color: #0028be;
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(38)};
      margin: 0;
    }
  }

  .c-overview-close__notes {
    list-style: none;
    margin: ${rem(22 / 2)} auto 0;
    width: ${rem(590 / 2)};

    @media ${breakpoints.lg} {
      line-height: calc(24 / 14);
      width: 100%;
      margin-top: ${rem(18)};
    }
  }

  .c-overview-close__notes-item {
    font-size: ${rem(22 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(14)};
    }
  }

  .c-overview-schedule-head {
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${rem(70 / 2)};
    margin: 0 auto;
    font-size: ${rem(32 / 2)};
    background-color: #f2f2f2;
    letter-spacing: 0.06em;
    font-family: ${fontFamily.zenKaku};

    @media ${breakpoints.lg} {
      height: ${rem(56)};
      font-size: ${rem(24)};
    }

    .is-strong {
      font-size: ${rem(42 / 2)};
      font-weight: 700;
      color: #0093ff;

      @media ${breakpoints.lg} {
        font-size: ${rem(32)};
      }
    }
  }

  .c-overview-items {
    display: flex;
    flex-direction: column;
    gap: ${rem(45)};

    @media ${breakpoints.lg} {
      gap: ${rem(92)};
    }
  }

  .c-overview-item {
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
      margin-left: auto;
      margin-right: auto;
    }
  }

  .c-overview-item__head {
    width: ${rem(600 / 2)};
    position: absolute;
    top: ${rem(-46 / 2)};
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    padding-top: ${rem(10)};
    align-items: baseline;
    height: ${rem(92 / 2)};
    font-size: ${rem(42 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    color: #0028be;
    text-align: center;
    background-color: #ffee71;
    border: solid #000 ${rem(2)};
    border-radius: ${rem(20 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      height: ${rem(66)};
      top: ${rem(-33)};
      border-radius: ${rem(20)};
      font-size: ${rem(32)};
      border: solid #000 ${rem(3)};
    }

    .is-small {
      font-size: ${rem(28 / 2)};
    }
  }

  .c-overview-item__contents {
    border: solid #000 ${rem(2)};
    background-color: #fff;
    border-radius: ${rem(20 / 2)};
    padding: ${rem(40)} ${rem(25 / 2)} ${rem(60 / 2)};

    @media ${breakpoints.lg} {
      border: solid #000 ${rem(3)};
      border-radius: ${rem(20)};
      padding: ${rem(46)} ${rem(20)} ${rem(40)};
    }
  }

  .c-overview-item__lead {
    margin: 0 auto ${rem(16)};
    font-size: ${rem(32 / 2)};
    line-height: calc(58 / 32);
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      max-width: ${rem(682)};
      margin-top: ${rem(10)};
      font-size: ${rem(24)};
      line-height: calc(38 / 24);
    }

    .is-strong {
      color: #0093ff;
      font-weight: 700;
    }

    .is-underline {
      text-decoration: underline;
      text-decoration-color: #000;
      text-underline-offset: ${rem(7)};
      text-decoration-thickness: ${rem(1)};

      @media ${breakpoints.lg} {
        text-decoration-thickness: ${rem(3)};
      }
    }
  }

  .c-overview-item__arrow {
    display: block;
    width: ${rem(15)};
    margin: ${rem(15)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(32.83)};
      margin-top: ${rem(21)};
    }
  }

  .c-overview-item__schedule {
    margin: ${rem(15)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      margin-top: ${rem(60)};
    }
  }

  .c-overview-item__timetable {
    margin: ${rem(15)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      margin-top: ${rem(21)};
    }
  }

  .c-overview-item__subject {
    margin: ${rem(15)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      margin-top: ${rem(21)};
    }
  }

  .c-overview-item__text {
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    line-height: calc(48 / 28);
    margin: ${rem(5)} 0 ${rem(0)};
    text-align: left;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      text-align: center;
      margin-top: ${rem(14)};
      margin-bottom: ${rem(14)};
    }
  }

  .c-overview-item__notes {
    margin: ${rem(12.5 / 2)} auto 0;
    list-style: none;
    font-size: ${rem(22 / 2)};
    line-height: calc(34 / 22);

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      margin-top: ${rem(15)};
      font-size: ${rem(14)};
      line-height: calc(24 / 14);
    }
  }

  .c-overview-item__notes-item {
    margin: 0;
    padding: 0;
  }

  .c-overview-example {
    margin: ${rem(70 / 2)} auto;
    padding: 0 0 ${rem(24 / 2)} 0;
    border-bottom: solid #0093ff ${rem(2)};
    margin: ${rem(64 / 2)} auto 0;
    width: ${rem(620 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(800)};
      margin-top: ${rem(52.5)};
      margin-bottom: ${rem(0)};
      padding: 0 0 ${rem(12)} 0;
      border-bottom-width: ${rem(3)};
    }
  }

  .c-overview-example__head {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(36 / 2)};
    font-family: bold;
    word-break: keep-all;
    color: #0093ff;
    letter-spacing: 0.06em;
    font-family: ${fontFamily.zenKaku};

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
    }

    &::before,
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: ${rem(2)};
      background-color: #0093ff;

      @media ${breakpoints.lg} {
        height: ${rem(3)};
      }
    }

    &::before {
      margin: 0 ${rem(18 / 2)} 0 0;
    }

    &::after {
      margin: 0 0 0 ${rem(18 / 2)};
    }
  }

  .c-overview-example__contents {
    @media ${breakpoints.lg} {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: ${rem(6)} 0 0 0;
    }
  }

  .c-overview-example__icon-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${rem(12 / 2)} 0 0 0;

    @media ${breakpoints.lg} {
      margin: 0;
    }
  }

  .c-overview-example__text {
    text-align: center;
    margin: 0;
    line-height: calc(50 / 28);
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      line-height: calc(50 / 18);
    }

    .is-strong {
      font-weight: 700;
      color: #0093ff;
    }
  }

  .c-overview-example__icon {
    width: ${rem(70 / 2)};
    height: ${rem(40.01 / 2)};
    margin: 0 ${rem(10 / 2)} 0 0;

    @media ${breakpoints.lg} {
      width: ${rem(56.85)};
      height: ${rem(32.49)};
      margin: 0 ${rem(10)} 0 0;
    }
  }

  .c-overview-schedule-list,
  .c-overview-timetable-list {
    margin: 0;
  }

  .c-overview-schedule-list__item,
  .c-overview-timetable-list__item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: ${rem(18 / 2)} 0 ${rem(18 / 2)} ${rem(120 / 2)};
    font-size: ${rem(28 / 2)};
    border-bottom: solid #adadad ${rem(1)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      padding: ${rem(11)} ${rem(220)} ${rem(12)} ${rem(270)};
      font-size: ${rem(18)};
    }
  }

  .c-overview-schedule-list__item-icon,
  .c-overview-timetable-list__item-icon {
    width: ${rem(19.93 / 2)};
    height: ${rem(18.65 / 2)};
    margin: 0 ${rem(10 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(19)};
      height: ${rem(17.78)};
      margin: 0 ${rem(10)};
    }
  }

  .c-format-text {
    text-align: center;
    margin: 0;
    padding: ${rem(16 / 2)} 0;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      padding-top: ${rem(20)};
      padding-bottom: ${rem(16)};
      font-size: ${rem(18)};
    }
  }

  .c-format-list {
    list-style: none;
    margin: 0 auto;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      display: flex;
      justify-content: space-between;
      gap: ${rem(60)};
      width: ${rem(800)};
    }
  }

  .c-format-list__item {
    text-align: center;
    margin: 0;
    padding: ${rem(16 / 2)} 0;
    border-bottom: solid #adadad ${rem(1)};
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      width: 100%;
      padding: ${rem(12)} 0;
      border-top: solid #adadad ${rem(1)};
      border-bottom: solid #adadad ${rem(1)};
      font-size: ${rem(18)};
    }

    &:first-child {
      border-top: solid #adadad ${rem(1)};
    }
  }

  .u-text-bold {
    font-weight: bold;
  }

  .u-text-highlight-bold {
    color: #0093ff;
    font-weight: bold;
    background: #ffee71;
  }

  .text-underline {
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: ${rem(3)};
    text-decoration-thickness: ${rem(2)};

    @media ${breakpoints.lg} {
      text-decoration-thickness: ${rem(3)};
    }
  }

  .is-sp {
    display: block;

    @media ${breakpoints.lg} {
      display: none;
    }
  }

  .is-pc {
    display: none;

    @media ${breakpoints.lg} {
      display: block;
    }
  }
`

export const CourseSection: (props: CourseSectionProps) => JSX.Element = StyledComponent

export default CourseSection
