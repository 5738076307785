import React from 'react'
import styled from 'styled-components'
import { rem, summerColor, fontFamily, breakpoints, innerWidth } from 'variables/_index'
import { useInView } from 'react-intersection-observer'
import CourseButton from 'components/section/summer/atom/courseButton'

// 画像
import CourseJuniorImage from 'assets/images/summer/course/junior/course-junior-img.webp'
import CoursePointIllust1 from 'assets/images/summer/course/ill_book.webp'
import CoursePointIllust2 from 'assets/images/summer/course/ill_pen.webp'
import CoursePointCycleImage from 'assets/images/summer/course/course_point1_flow.svg'
import CoursePointArrowImage from 'assets/images/summer/course/course_point_arrow.webp'
import CourseChuPhotoSP from 'assets/images/summer/course/junior/course_chu_photo_sp.webp'
import CourseChuPhotoPC from 'assets/images/summer/course/junior/course_chu_photo_pc.webp'
import CourseMoreIllust1 from 'assets/images/summer/course/course-more-illust1.svg'
import CourseMoreIllust2 from 'assets/images/summer/course/course-more-illust2.svg'
import CourseSaraniPhoto from 'assets/images/summer/course/junior/course_sarani_photo.webp'
import CoursePoint2Photo from 'assets/images/summer/course/junior/course_point2_photo.webp'
import CourseFusenIllust from 'assets/images/summer/course/ill_fusen.webp'
import CourseOnlinePhoto from 'assets/images/summer/course/course_online_photo.webp'
import classNames from 'classnames'

export interface CourseJuniorHighSchoolProps {
  readonly noProps?: string
}

type CourseJuniorHighSchoolPropsClassName = CourseJuniorHighSchoolProps & {
  readonly className: string
}

function Component({ className }: CourseJuniorHighSchoolPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: ref2, inView: inView2 } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  return (
    <div className={classNames(className, 's-course-junior-high-school')}>
      <h3 className="c-course-content-head">中学生コース</h3>

      <img className="s-course-junior-high-school__img" src={CourseJuniorImage} alt="" width={302} height={189} decoding="async" loading="lazy" />
      <CourseButton href="#junior-overview" />

      <div className="c-point-box s-course-junior-high-school__point-box">
        <h4 ref={ref} className="c-point-box__head" data-anim={inView}>
          <img className="c-point-box__illust1" src={CoursePointIllust1} alt="" width={143.78} height={158.45} decoding="async" loading="lazy" />
          <span className="is-color">
            <span className="is-large">2</span>つ
          </span>
          のポイント
          <img className="c-point-box__illust2" src={CoursePointIllust2} alt="" width={134.61} height={122.95} decoding="async" loading="lazy" />
        </h4>
        <ul className="c-course-points">
          <li className="c-course-point">
            <span className="is-number">1.</span>点数があがるしくみ
          </li>
          <li className="c-course-point">
            <span className="is-number">2.</span>つまずかないサポート
          </li>
        </ul>

        <div className="c-point-head s-course-junior-high-school__point1-head">
          <p className="c-point-head__badge">Point 1</p>
          <h5 className="c-point-head__text">点数があがるしくみ</h5>
        </div>

        <p className="c-course-text">
          <span className="is-strong">中学入学時から今までに学習してきた範囲を総復習！</span>
          苦手を見つけ、苦手克服サイクルで「わかった!」に変えます。
        </p>

        <div className="c-cycle-step s-course-junior-high-school__cycle-step">
          <div className="c-cycle-step__step1 c-cycle-figure">
            <p className="c-cycle-figure__caption">(苦手克服サイクル)</p>
            <img className="c-cycle-figure__image" src={CoursePointCycleImage} alt="" width={510} height={472} decoding="async" loading="lazy" />
          </div>
          <img className="c-cycle-step__arrow" src={CoursePointArrowImage} alt="" width={100} height={100} decoding="async" loading="lazy" />
          <p className="c-cycle-step__step2 c-course-text">
            <span className="is-strong">苦手克服サイクルをくり返すことで苦手がなくなり</span>
            基礎学力が身につく!
          </p>
          <img className="c-cycle-step__arrow" src={CoursePointArrowImage} alt="" width={37.37} height={63.55} decoding="async" loading="lazy" />
          <div className="c-cycle-step__step3">
            <p className="c-course-text">
              <span className="is-strong">2学期の学校の授業がよくわかる</span>
              ようになり、
            </p>
            <picture className="c-cycle-step__result">
              <source srcSet={CourseChuPhotoPC} media="(min-width: 46.5rem)" width={640.6} height={265.89} />
              <img
                className="s-course-junior-high-school__chugaku-photo"
                src={CourseChuPhotoSP}
                alt=""
                width={256}
                height={263}
                decoding="async"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
        <div className="c-more-box">
          <span className="c-more-box__head">さらに</span>

          <ul className="c-more-box__list">
            <li className="c-more-box__item1">
              <span className="c-more-box__img1">
                <img src={CourseMoreIllust1} alt="" width={36.23} height={35.74} decoding="async" loading="lazy" />
              </span>
              自宅でも集中が続くかな
            </li>
            <li className="c-more-box__item2">
              うちの子にもできるかな？
              <span className="c-more-box__img2">
                <img src={CourseMoreIllust2} alt="" width={36.22} height={35.74} decoding="async" loading="lazy" />
              </span>
            </li>
          </ul>

          <p className="c-more-box__text1">という不安は</p>

          <p className="c-more-box__lead">
            Fitオリジナル
            <br />
            学習システムが解決！
          </p>

          <img className="c-more-box__image" src={CourseSaraniPhoto} alt="" width={246.04} height={165.34} decoding="async" loading="lazy" />

          <p className="c-more-box__text2">
            システムによって講師はお子さまの理解状況をリアルタイムで把握できます。
            <span className="is-strong">一人で悩むことなく適切な解説を受けることができる</span>
            ので苦手がどんどん「わかった」に変わります。
          </p>
        </div>

        <div className="c-point-head s-course-junior-high-school__point2-head">
          <p className="c-point-head__badge">Point 2</p>
          <h5 className="c-point-head__text">つまずかないサポート</h5>
        </div>

        <img className="c-point2-image" src={CoursePoint2Photo} alt="" width={230} height={159.52} decoding="async" loading="lazy" />
        <p className="c-course-text">
          指導経験豊富な担任が、勉強のお悩みをヒアリングしながら講習の提案や学習アドバイスを行います。自分に合う勉強法が分かるから
          <span className="is-strong">勉強のやる気アップにつながります！</span>
        </p>
        <div className="c-online-top">
          <div className="c-online-top__image">
            <img src={CourseFusenIllust} alt="" width={41} height={30.55} decoding="async" loading="lazy" />
          </div>
        </div>

        <h6 className="c-sub-head">
          <span className="c-sub-head__deco" data-type="left"></span>
          <span className="c-sub-head__text">オンライン自習室</span>
          <span className="c-sub-head__deco" data-type="right"></span>
        </h6>

        <div className="c-online-image">
          <img src={CourseOnlinePhoto} alt="" width={280} height={175} decoding="async" loading="lazy" />
        </div>

        <p className="c-course-text">
          授業以外の時間は、自習室を使って勉強することができます。
          <span className="is-strong">勉強を頑張る仲間が一緒だから、集中して取り組むことができます。</span>
        </p>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseJuniorHighSchoolPropsClassName>`
  border-top: ${rem(2)} solid #000;
  background-color: #d1efff;
  padding: ${rem(70 / 2)} ${rem(40 / 2)} ${rem(80 / 2)};

  @media ${breakpoints.lg} {
    border-top-width: ${rem(3)};
    padding-top: ${rem(86)};
    padding-bottom: ${rem(80)};
  }

  &.s-course-junior-high-school {
  }

  .s-course-junior-high-school__point-box {
    margin-top: ${rem(48)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(80)};
    }
  }

  .s-course-junior-high-school__cycle-step {
    margin-top: ${rem(16)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(42)};
    }
  }

  .s-course-junior-high-school__img {
    width: ${rem(302 / 2)};
    margin: ${rem(40 / 2)} auto 0;
    display: block;

    @media ${breakpoints.lg} {
      width: ${rem(262)};
      margin: ${rem(49.2)} auto 0 auto;
    }
  }

  .s-course-junior-high-school__chugaku-photo {
    display: block;
    width: ${rem(256)};
    margin: ${rem(16)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(640)};
      margin-top: ${rem(2)};
    }
  }

  .s-course-junior-high-school__point1-head {
    margin-top: ${rem(46)};
  }

  .s-course-junior-high-school__point2-head {
    margin-top: ${rem(60)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(120)};
    }
  }

  .c-more-box {
    position: relative;
    width: ${rem(580 / 2)};
    margin: ${rem(56)} auto 0;
    padding: ${rem(79 / 2)} 0 ${rem(24)} 0;
    border: solid #00c8ff ${rem(2)};
    border-radius: ${rem(20 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(700)};
      margin-top: ${rem(62)};
      padding-top: ${rem(79)};
      padding-bottom: ${rem(40)};
      border: solid #00c8ff ${rem(3)};
      border-radius: ${rem(20)};
    }
  }

  .c-more-box__head {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: ${rem(200 / 2)};
    font-size: ${rem(38 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    top: ${rem(-30 / 2)};
    left: 50%;
    transform: translateX(-50%);
    height: ${rem(60 / 2)};
    color: #fff;
    border-radius: 9999px;
    background-color: #00c8ff;
    letter-spacing: 0.1em;

    @media ${breakpoints.lg} {
      width: ${rem(160)};
      top: ${rem(-24)};
      height: ${rem(48)};
      font-size: ${rem(29)};
    }
  }

  .c-more-box__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(15 / 2)};
    width: ${rem(427 / 2)};
    list-style: none;
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(341.6)};
      gap: ${rem(12)};
    }
  }

  .c-more-box__item1,
  .c-more-box__item2 {
    width: 100%;
    margin: 0 auto;
    padding: ${rem(10 / 2)} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddf3ff;
    border-radius: ${rem(10 / 2)};
    font-size: ${rem(32 / 2)};
    font-weight: 700;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      padding-top: ${rem(9)};
      padding-bottom: ${rem(9)};
      font-size: ${rem(23)};
      border-radius: ${rem(10)};
    }
  }

  .c-more-box__item1 {
    position: relative;
    width: ${rem(404 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(323.2)};
    }
  }

  .c-more-box__img1 {
    position: absolute;
    top: ${rem(-24 / 2)};
    left: ${rem(-48 / 2)};
    width: ${rem(45.285 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(-16)};
      left: ${rem(-42)};
      width: ${rem(45.285)};
    }
  }

  .c-more-box__item2 {
    position: relative;
    width: ${rem(427 / 2)};

    @media ${breakpoints.lg} {
      width: ${rem(341.6)};
    }
  }

  .c-more-box__img2 {
    position: absolute;
    top: ${rem(-40 / 2)};
    right: ${rem(-48 / 2)};
    width: ${rem(45.285 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(-30)};
      right: ${rem(-48)};
      width: ${rem(45.285)};
    }
  }

  .c-more-box__lead {
    margin: ${rem(41 / 2)} 0 0 0;
    text-align: center;
    font-size: ${rem(42 / 2)};
    font-weight: bold;
    color: #0093ff;
    line-height: calc(68 / 42);
    text-decoration: underline;
    text-decoration-color: #000;
    text-decoration-thickness: ${rem(1.5)};
    text-underline-offset: ${rem(6)};
    font-family: ${fontFamily.zenKaku};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      margin-top: ${rem(28)};
      font-size: ${rem(32)};
      line-height: calc(58 / 32);
      text-decoration-thickness: ${rem(3)};
      line-height: calc(50 / 32);
    }
  }

  .c-more-box__image {
    display: block;
    width: ${rem(492 / 2)};
    margin: ${rem(42 / 2)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(340)};
      margin-top: ${rem(36)};
    }
  }

  .c-more-box__text1,
  .c-more-box__text2 {
    font-size: ${rem(32 / 2)};
    margin: ${rem(18 / 2)} auto 0;
    line-height: calc(58 / 32);
    max-width: ${rem(520 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      margin-top: ${rem(32)};
      max-width: ${rem(460)};
    }

    .is-strong {
      color: #0093ff;
      font-weight: 700;
    }
  }

  .c-more-box__text1 {
    margin-top: ${rem(14)};
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(23)};
      margin-top: ${rem(18)};
    }
  }

  .c-more-box__text2 {
    margin-top: ${rem(4)};

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      margin-top: ${rem(20)};
    }
  }

  .c-point2-image {
    display: block;
    width: ${rem(460 / 2)};
    margin: ${rem(40 / 2)} auto ${rem(10)};

    @media ${breakpoints.lg} {
      width: ${rem(368)};
      margin-top: ${rem(42)};
      margin-bottom: ${rem(20)};
    }
  }

  .c-point2-text,
  .c-point2-text2 {
    font-size: ${rem(32 / 2)};
    margin: ${rem(18 / 2)} auto 0;
    line-height: calc(58 / 32);
    max-width: ${rem(560 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(18)};
      margin-top: ${rem(35)};
      max-width: ${rem(620)};
    }

    .is-strong {
      color: #0093ff;
      font-weight: 700;
    }
  }

  .c-point2-text {
    margin-top: ${rem(2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }
  }

  .c-point2-text2 {
    margin-top: ${rem(5)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(18)};
    }
  }

  .c-online-image {
    margin: ${rem(44 / 2)} auto ${rem(10)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(42)};
      margin-bottom: ${rem(20)};
    }

    img {
      margin: 0;
      width: ${rem(560 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(448)};
      }
    }
  }

  .point-item-text1 {
    margin: ${rem(40 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(45)} auto 0 auto !important;
    }
  }

  .point-item-text2 {
    margin: ${rem(45 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(27.1)} auto 0 auto !important;
    }
  }

  .point-item-text3 {
    margin: ${rem(45 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(21.2)} auto 0 auto !important;
    }
  }

  .point-item-text4 {
    margin: ${rem(40 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(30)} auto 0 auto !important;
    }
  }

  .point-item-text5 {
    margin: ${rem(30 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(30)} auto 0 auto !important;
    }
  }

  .arrow-1 {
    margin: ${rem(43 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(37.1)} auto 0 auto !important;
    }
  }

  .arrow-2 {
    margin: ${rem(48 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(29)} auto 0 auto !important;
    }
  }

  .sub-head1 {
    margin: ${rem(80 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(53.4)} auto 0 auto !important;
    }
  }

  .sub-head2 {
    margin: ${rem(80 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(50.4)} auto 0 auto !important;
    }
  }

  .more-text {
    width: ${rem(520 / 2)} !important;
    margin: ${rem(26 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      width: ${rem(460)} !important;
      margin: ${rem(20.8)} auto 0 auto !important;
    }
  }

  .text-bold {
    font-weight: bold;
  }

  .text-highlight-bold {
    color: #ff1a56;
    font-weight: bold;
  }

  .text-underline {
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: ${rem(3)};
    text-decoration-thickness: ${rem(2)};

    @media ${breakpoints.lg} {
      text-decoration-thickness: ${rem(3)};
    }
  }

  .is-sp {
    display: block;

    @media ${breakpoints.lg} {
      display: none;
    }
  }

  .is-pc {
    display: none;

    @media ${breakpoints.lg} {
      display: block;
    }
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const CourseJuniorHighSchool: (props: CourseJuniorHighSchoolProps) => JSX.Element = StyledComponent

export default CourseJuniorHighSchool
