import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily, summerColor } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'

// 画像
import MvText1Img from 'assets/images/summer/mv/mv-text1.svg'
import MvText2Img from 'assets/images/summer/mv/mv-text2.svg'
import MvText3Img from 'assets/images/summer/mv/mv-text3.svg'
import MvText4Img from 'assets/images/summer/mv/mv-text4.svg'
import MvPhotoPcImg from 'assets/images/summer/mv/mv-photo-pc.webp'
import MvPhotoSpImg from 'assets/images/summer/mv/mv-photo-sp.webp'
import MvFusenGImg from 'assets/images/summer/mv/mv-fusen-g.webp'
import MvFusenPImg from 'assets/images/summer/mv/mv-fusen-p.webp'
import MvTangochouImg from 'assets/images/summer/mv/mv-tangochou.webp'
import MvPenImg from 'assets/images/summer/mv/mv-pen.webp'
import MvTextExclImg from 'assets/images/summer/mv/mv-text-excl.svg'
import MvAchievements1Img from 'assets/images/summer/mv/achievements1.webp'
import MvAchievements2Img from 'assets/images/summer/mv/achievements2.webp'
import MvLogoImg from 'assets/images/summer/mv/logo.webp'
import MvPopSpImg from 'assets/images/summer/mv/main-visual-pop-sp.webp'
import MvPopPcImg from 'assets/images/summer/mv/main-visual-pop-pc.webp'

export interface MainVisualSectionProps {
  readonly noProps?: string
}

type MainVisualSectionPropsClassName = MainVisualSectionProps & {
  readonly className: string
}

function Component({ className }: MainVisualSectionPropsClassName) {
  const [currentImage, setCurrentImage] = useState<boolean>(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(state => !state)
    }, 5400)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={className}>
      <div className="l-main-visual-inner">
        <div className="c-main-visual-logo">
          <div className="c-lead-text">
            <span>オ</span>
            <span>ン</span>
            <span>ラ</span>
            <span>イ</span>
            <span>ン</span>
          </div>

          <h1 className="c-main-visual-head">
            <img className="c-main-visual-head__text1" src={MvText1Img} alt="夏" width={73.18} height={69.485} decoding="async" loading="eager" />
            <img className="c-main-visual-head__text2" src={MvText2Img} alt="期" width={73.54} height={70.79} decoding="async" loading="eager" />
            <img className="c-main-visual-head__text3" src={MvText3Img} alt="講" width={71.835} height={71.085} decoding="async" loading="eager" />
            <img className="c-main-visual-head__text4" src={MvText4Img} alt="習" width={68.87} height={69.355} decoding="async" loading="eager" />
          </h1>
        </div>

        <picture>
          <source srcSet={MvPopPcImg} media="(min-width: 768px)" width={183.17} height={158.7} />
          <img
            src={MvPopSpImg}
            alt="5日間無料体験できます"
            className="c-main-visual-pop"
            width="119.205"
            height="214"
            decoding="async"
            loading="eager"
          />
        </picture>

        <img className="c-main-visual-fusen1" src={MvFusenGImg} alt="" width={23.115} height={16.285} decoding="async" loading="eager" />
        <img className="c-main-visual-fusen2" src={MvFusenPImg} alt="" width={22.555} height={12.7} decoding="async" loading="eager" />
        <img className="c-main-visual-tangochou" src={MvTangochouImg} alt="" width={37.74} height={32.205} decoding="async" loading="eager" />
        <img className="c-main-visual-pen" src={MvPenImg} alt="" width={53.5} height={30} decoding="async" loading="eager" />

        <picture>
          <source srcSet={MvPhotoPcImg} media="(min-width: 768px)" width={653} height={540} />
          <img className="c-main-image" src={MvPhotoSpImg} alt="" width={375} height={317.5} decoding="async" loading="eager" />
        </picture>

        <p className="c-main-visual-lead">
          <span className="c-main-visual-lead__line1">
            <span className="c-main-visual-lead__line1-in">
              <span className="is-blue">“わかる”</span>
              <span className="is-orange">が増えて</span>
            </span>
          </span>
          <span className="c-main-visual-lead__line2">
            <span className="c-main-visual-lead__line2-in">
              <span className="is-orange">2学期で</span>
              <span className="is-blue">点数アップ</span>
              <img src={MvTextExclImg} alt="!" width={4.92} height={25.185} decoding="async" loading="eager" />
            </span>
          </span>
        </p>

        <div className="c-main-visual-meta">
          <span className="c-main-visual-meta__date">実施期間：7/15(月)ー8/31(土)</span>
          <span className="c-main-visual-meta__subject">小学生｜中学生｜高校生</span>
        </div>
      </div>

      <div className="c-main-under">
        <img className="c-main-under__logo" src={MvLogoImg} alt="" width={206.5} height={49.98} decoding="async" loading="eager" />
        <div className="c-main-under__achievements">
          <div className="c-main-under__contents">
            <img className="c-main-under__image" src={MvAchievements1Img} alt="" width={152.75} height={82.515} decoding="async" loading="eager" />
            <img className="c-main-under__image" src={MvAchievements2Img} alt="" width={152.75} height={82.515} decoding="async" loading="eager" />
          </div>
          <p className="c-main-under__text">&#8251;運営会社CKCネットワーク株式会社全体の実績です。</p>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<MainVisualSectionPropsClassName>`
  position: relative;
  background-color: #90e1ff;
  padding: ${rem(140 / 2)} 0 0 0;

  @media ${breakpoints.lg} {
    padding-top: ${rem(132)};
  }

  @keyframes lead {
    0% {
      opacity: 0;
      translate: -100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes leadIn {
    0% {
      opacity: 0;
      translate: 100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(10)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .l-main-visual-inner {
    max-width: 1080px;
    margin: 0 auto;
    position: relative;
    padding-top: ${rem(75)};
    overflow: hidden;

    @media ${breakpoints.lg} {
      padding-top: ${rem(0)};
      padding-bottom: ${rem(38)};
    }
  }

  .c-main-visual-logo {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${rem(6)};
    position: absolute;
    inset-inline: 0;
    margin-inline: auto;
    top: ${rem(0)};

    @media ${breakpoints.lg} {
      gap: ${rem(14)};
      top: ${rem(35)};
      margin-inline: 0;
    }
  }

  .c-lead-text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${rem(5)};

    > span {
      display: grid;
      place-items: center;
      width: ${rem(57.65 / 2)};
      height: ${rem(57.65 / 2)};
      font-family: ${fontFamily.zenKakuA};
      font-weight: 700;
      font-size: ${rem(54 / 2)};
      line-height: 1;
      text-align: left;
      color: #fff;
      background: #0028be;
      border-radius: ${rem(3)};
      overflow: hidden;

      @media ${breakpoints.lg} {
        width: ${rem(47.77)};
        height: ${rem(47.77)};
        font-size: ${rem(44)};
        gap: ${rem(10)};
      }
    }

    @media ${breakpoints.lg} {
      gap: ${rem(10)};
    }
  }

  .e-main-visual-head {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;

    @media ${breakpoints.lg} {
      width: ${rem(523.42)};
    }
  }

  .c-main-visual-head {
    --main-visual-head-text1-delay: 0s;
    --main-visual-head-text2-delay: 0.5s;
    --main-visual-head-text3-delay: 1s;
    --main-visual-head-text4-delay: 1.5s;
    --main-visual-head-text-duration: 1s;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: ${rem(630 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(523.42)};
    }
  }

  .c-main-visual-head__text1 {
    width: ${rem(148.63 / 2)};
    margin: 0 ${rem(11.5 / 2)} 0 0;
    opacity: 0;
    animation: fadeIn var(--main-visual-head-text-duration) var(--main-visual-head-text1-delay) forwards;

    @media ${breakpoints.lg} {
      width: ${rem(118.15)};
      margin-right: ${rem(13.6)};
    }
  }

  .c-main-visual-head__text2 {
    width: ${rem(146.21 / 2)};
    margin: 0 ${rem(22.6 / 2)} 0 0;
    opacity: 0;
    animation: fadeIn var(--main-visual-head-text-duration) var(--main-visual-head-text2-delay) forwards;

    @media ${breakpoints.lg} {
      width: ${rem(117.39)};
      margin-right: ${rem(22.7)};
    }
  }

  .c-main-visual-head__text3 {
    width: ${rem(143.34 / 2)};
    margin: 0 ${rem(22 / 2)} 0 0;
    opacity: 0;
    animation: fadeIn var(--main-visual-head-text-duration) var(--main-visual-head-text3-delay) forwards;

    @media ${breakpoints.lg} {
      width: ${rem(115.01)};
      margin-right: ${rem(22.1)};
    }
  }

  .c-main-visual-head__text4 {
    width: ${rem(137.32 / 2)};
    opacity: 0;
    animation: fadeIn var(--main-visual-head-text-duration) var(--main-visual-head-text4-delay) forwards;

    @media ${breakpoints.lg} {
      width: ${rem(110.01)};
    }
  }

  .c-main-visual-pop {
    --main-visual-pop-delay: 1.5s;
    --main-visual-pop-duration: 1.5s;

    margin: 0;
    font-family: ${fontFamily.zenKakuA};
    font-weight: 700;
    font-size: ${rem(43 / 2)};
    letter-spacing: 0.03em;
    line-height: calc(52 / 43);
    color: #0028be;
    position: absolute;
    z-index: 2;
    top: ${rem(135)};
    left: ${rem(58 / 2)};
    width: ${rem(238.41 / 2)};
    text-align: center;
    opacity: 0;
    animation: fadeIn var(--main-visual-pop-duration) var(--main-visual-pop-delay) forwards;

    @media ${breakpoints.lg} {
      width: ${rem(170.94)};
      font-size: ${rem(29)};
      top: ${rem(80)};
      left: ${rem(540)};
    }

    @media ${breakpoints.lg} {
      font-size: ${rem(29)};
      line-height: calc(35 / 29);
      width: ${rem(183.17)};
    }
  }

  .c-main-visual-fusen1 {
    --main-visual-fusen1-delay: 4.2s;
    --main-visual-fusen1-duration: 1s;

    margin: 0;
    position: absolute;
    top: ${rem(133)};
    left: ${rem(5)};
    width: ${rem(43.43 / 2)};
    opacity: 0;
    transform: translateY(${rem(10)});
    animation: slideFadeIn var(--main-visual-fusen1-duration) var(--main-visual-fusen1-delay) forwards;
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(20)};
      left: ${rem(536)};
      width: ${rem(31.84)};
    }
  }

  .c-main-visual-fusen2 {
    --main-visual-fusen2-delay: 4s;
    --main-visual-fusen2-duration: 1s;

    margin: 0;
    position: absolute;
    top: ${rem(114)};
    left: ${rem(22)};
    width: ${rem(51.67 / 2)};
    opacity: 0;
    transform: translateY(${rem(10)});
    animation: slideFadeIn var(--main-visual-fusen2-duration) var(--main-visual-fusen2-delay) forwards;
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(37)};
      left: ${rem(584)};
      width: ${rem(37.88)};
    }
  }

  .c-main-visual-tangochou {
    --main-visual-tangochou-delay: 3.8s;
    --main-visual-tangochou-duration: 1s;

    margin: 0;
    position: absolute;
    top: ${rem(142)};
    left: ${rem(129)};
    width: ${rem(91.01 / 2)};
    opacity: 0;
    transform: translateY(${rem(10)});
    animation: slideFadeIn var(--main-visual-tangochou-duration) var(--main-visual-tangochou-delay) forwards;
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(90)};
      left: ${rem(695)};
      width: ${rem(66.72)};
    }
  }

  .c-main-visual-pen {
    --main-visual-pen-delay: 3.6s;
    --main-visual-pen-duration: 1s;

    margin: 0;
    position: absolute;
    top: ${rem(342 / 2)};
    left: ${rem(140)};
    width: ${rem(122.47 / 2)};
    opacity: 0;
    transform: translateY(${rem(10)});
    animation: slideFadeIn var(--main-visual-pen-duration) var(--main-visual-pen-delay) forwards;
    z-index: 2;

    @media ${breakpoints.lg} {
      top: ${rem(140)};
      left: ${rem(698)};
      width: ${rem(89.35)};
    }
  }

  .c-main-image {
    display: block;
    width: ${rem(375)};
    margin-left: auto;
    margin-bottom: 0;

    @media ${breakpoints.lg} {
      width: ${rem(653)};
    }
  }

  .c-main-visual-lead {
    --main-visual-lead-duration: 0.6s;
    --main-visual-lead1-delay: 2.5s;
    --main-visual-lead2-delay: 3s;

    margin: ${rem(-10)} 0 0;
    width: 100%;
    position: relative;

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
      position: absolute;
      top: ${rem(284)};
      left: ${rem(40)};
      width: ${rem(438)};
    }
  }

  .c-main-visual-lead__line1,
  .c-main-visual-lead__line2 {
    display: block;
    width: fit-content;
    margin-inline: auto;
    overflow: hidden;
    translate: -100% 0;
  }

  .c-main-visual-lead__line1 {
    animation: lead linear var(--main-visual-lead-duration) forwards var(--main-visual-lead1-delay);
  }

  .c-main-visual-lead__line2 {
    animation: lead linear var(--main-visual-lead-duration) forwards var(--main-visual-lead2-delay);
    margin-top: ${rem(6)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(6)};
    }
  }

  .c-main-visual-lead__line1-in,
  .c-main-visual-lead__line2-in {
    display: block;
    width: fit-content;
    font-family: ${fontFamily.zenKakuA};
    font-weight: 700;
    background: #fff;
    letter-spacing: 0.04em;
    text-indent: 0.04em;
    padding: ${rem(1.5)} ${rem(10)} ${rem(3.5)};
    font-size: ${rem(58 / 2)};
    font-family: ${fontFamily.zenKakuA};
    line-height: 1;
    translate: 100% 0;

    .is-blue {
      font-size: ${rem(58 / 2)};
      color: #0028be;

      @media ${breakpoints.lg} {
        font-size: ${rem(46)};
      }
    }

    .is-orange {
      font-size: ${rem(50 / 2)};
      color: #ff8b00;

      @media ${breakpoints.lg} {
        font-size: ${rem(39)};
      }
    }
  }

  .c-main-visual-lead__line1-in {
    animation: leadIn linear var(--main-visual-lead-duration) forwards var(--main-visual-lead1-delay);
  }

  .c-main-visual-lead__line2-in {
    animation: leadIn linear var(--main-visual-lead-duration) forwards var(--main-visual-lead2-delay);
    translate: 100% 0;

    img {
      width: ${rem(11)};
      vertical-align: bottom;

      @media ${breakpoints.lg} {
        width: ${rem(20)};
      }
    }
  }

  .c-main-visual-meta {
    margin-top: ${rem(10)};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: ${rem(6)};

    @media ${breakpoints.lg} {
      position: absolute;
      left: ${rem(52)};
      top: auto;
      margin-top: 0;
      bottom: ${rem(64)};
      width: fit-content;
      font-size: ${rem(21)};
      font-weight: 700;
      gap: ${rem(0)};
    }
  }

  .c-main-visual-meta__date {
    font-size: ${rem(32 / 2)};
    font-family: ${fontFamily.noto};
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;

    @media ${breakpoints.lg} {
      position: static;
      transform: none;
      padding: 0 0 ${rem(10)} 0;
      font-size: ${rem(21)};
      font-weight: 400;
    }
  }

  .c-main-visual-meta__subject {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${rem(58 / 2)};
    text-align: center;
    font-size: ${rem(30 / 2)};
    letter-spacing: 0.5em;
    font-weight: 500;
    color: #fff;
    background-color: ${summerColor.fitMain};

    @media ${breakpoints.lg} {
      position: static;
      transform: none;
      height: auto;
      background-color: transparent;
      border-top: ${rem(1)} solid currentColor;
      padding: ${rem(10)} 0 0 0;
      letter-spacing: 0.45em;
      color: ${summerColor.text};
      font-size: ${rem(21)};
      font-weight: 400;
    }
  }

  .c-main-under {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: ${rem(30)} 0;
    gap: ${rem(15)};

    @media ${breakpoints.lg} {
      flex-direction: row-reverse;
      padding: ${rem(50)} 0;
      gap: ${rem(48)};
      align-items: flex-start;
    }
  }

  .c-main-under__logo {
    width: ${rem(413 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(285)};
      margin-top: ${rem(18)};
    }
  }

  .c-main-under__achievements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .c-main-under__contents {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: ${rem(37 / 2)};

    @media ${breakpoints.lg} {
      gap: ${rem(26.8)};
    }
  }

  .c-main-under__image {
    width: ${rem(305.5 / 2)};
    margin: 0;

    @media ${breakpoints.lg} {
      width: ${rem(191.6)};
    }
  }

  .c-main-under__text {
    margin: ${rem(18 / 2)} 0 0 0;
    font-size: ${rem(20 / 2)};
    color: #656565;

    @media ${breakpoints.lg} {
      margin: ${rem(14)} 0 0 0;
      font-size: ${rem(14)};
    }
  }
`

export const MainVisualSection: (props: MainVisualSectionProps) => JSX.Element = StyledComponent

export default MainVisualSection
