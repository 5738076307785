import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, springColor } from 'variables/_index'

export interface TeacherCardProps {
  readonly title: string
  readonly imageElement: JSX.Element
  readonly position: string
  readonly text: JSX.Element
}

type TeacherCardPropsClassName = TeacherCardProps & {
  readonly className: string
}

function Component({ className, title, imageElement, position, text }: TeacherCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <p className="e-title">{title}</p>
        <p className="e-pos">{position}</p>
      </div>
      <div className="e-image">{imageElement}</div>
      <p className="e-text">{text}</p>
    </div>
  )
}

const StyledComponent = styled(Component)<TeacherCardPropsClassName>`
  background-color: #90e1ff;
  border-radius: ${rem(30 / 2)};
  position: relative;
  padding: ${rem(128 / 2)} ${rem(56 / 2)} ${rem(40 / 2)};
  color: #fff;

  @media ${breakpoints.lg} {
    border-width: 3px;
    border-radius: ${rem(20)};
    padding: ${rem(100)} ${rem(22)} ${rem(40)};
  }

  .e-head {
    background: #19b8e3;
    padding: ${rem(6)} ${rem(6)} ${rem(6)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${rem(4)};
    width: calc(100% - ${rem(22)});
    position: absolute;
    top: ${rem(-25 / 2)};
    inset-inline: 0;
    margin-inline: auto;
    border-radius: ${rem(10)};

    @media ${breakpoints.lg} {
      width: calc(100% - ${rem(25 * 2)});
      padding: ${rem(10)} ${rem(18)};
      top: ${rem(-20)};
      gap: ${rem(5)};
    }
  }

  .e-title {
    font-size: ${rem(36 / 2)};
    font-weight: 700;
    text-align: center;
    color: #fff;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(28)};
    }
  }

  .e-pos {
    font-size: ${rem(26 / 2)};
    color: #fff;
    margin: 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }
  }

  .e-image {
    text-align: center;

    @media ${breakpoints.lg} {
    }

    img {
      width: ${rem((260 + 8) / 2)};
      border: ${rem(8 / 2)} solid #fff;
      border-radius: 50%;
      margin: 0;

      @media ${breakpoints.lg} {
        width: ${rem(208 + 8 * 2)};
        border-width: ${rem(8)};
      }
    }
  }

  .e-text {
    margin: ${rem(3)} 0 0;
    line-height: calc(56 / 32);
    font-size: ${rem(32 / 2)};
    font-weight: 700;
    color: #0028be;
    background-image: linear-gradient(to top, #fff 1px, transparent 1px);
    background-size: 100% 1.75em; /* 行の高さ */
    line-height: 1.75; /* 文字の高さ */

    @media ${breakpoints.lg} {
      margin-top: ${rem(4)};
      font-size: ${rem(24)};
      line-height: calc(46 / 24);
      background-image: linear-gradient(to top, #fff 2px, transparent 2px);
      background-size: 100% 2em;
    }
  }
`

export const TeacherCard: (props: TeacherCardProps) => JSX.Element = StyledComponent

export default TeacherCard
