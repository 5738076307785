import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, summerColor, fontFamily, breakpoints, innerWidth } from 'variables/_index'
import { useInView } from 'react-intersection-observer'
import CourseButton from 'components/section/summer/atom/courseButton'

// 画像
import CourseHighImage from 'assets/images/summer/course/high/course-high-img.webp'
import IllustrationBookImage from 'assets/images/summer/course/ill_book.webp'
import IllustrationPenImage from 'assets/images/summer/course/ill_pen.webp'
import CoursePointArrowImage from 'assets/images/summer/course/course_point_arrow.webp'
import CoursePointCycleImage from 'assets/images/summer/course/course_point1_flow.svg'
import CourseKouPhotoSPImage from 'assets/images/summer/course/high/course_kou_photo_sp.webp'
import CourseKouPhotoPCImage from 'assets/images/summer/course/high/course_kou_photo_pc.webp'
import CourseHighPoint2Image from 'assets/images/summer/course/high/course-high-point2.webp'
import IllustrationFusenImage from 'assets/images/summer/course/ill_fusen.webp'
import CourseOnlinePhotoImage from 'assets/images/summer/course/course_online_photo.webp'
import classNames from 'classnames'

export interface CourseHighSchoolProps {
  readonly noProps?: string
}

type CourseHighSchoolPropsClassName = CourseHighSchoolProps & {
  readonly className: string
}

function Component({ className }: CourseHighSchoolPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: ref2, inView: inView2 } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  return (
    <div className={classNames(className, 's-course-high-school')}>
      <h3 className="c-course-content-head">高校生コース</h3>
      <img className="c-high-course-img" src={CourseHighImage} alt="" width="151" height="94.5" decoding="async" loading="lazy" />
      <CourseButton href="#high-overview" />

      <div className="c-point-box s-course-high-school__point-box">
        <h4 ref={ref} className="c-point-box__head" data-anim={inView}>
          <img className="c-point-box__illust1" src={IllustrationBookImage} alt="" width="71.89" height="79.225" decoding="async" loading="lazy" />
          <span className="is-color">
            <span className="is-large">2</span>つ
          </span>
          のポイント
          <img className="c-point-box__illust2" src={IllustrationPenImage} alt="" width="67.305" height="61.475" decoding="async" loading="lazy" />
        </h4>

        <ul className="c-course-points">
          <li className="c-course-point">
            <span className="is-number">1.</span>点数があがるしくみ
          </li>
          <li className="c-course-point">
            <span className="is-number">2.</span>つまずかないサポート
          </li>
        </ul>

        <div className="c-point-head s-course-high-school__point1-head">
          <p className="c-point-head__badge">Point 1</p>
          <h5 className="c-point-head__text">点数があがるしくみ</h5>
        </div>

        <p className="c-course-text">
          <span className="is-strong">高校入学時から今までに学習してきた範囲を総復習！</span>
          苦手を見つけ、苦手克服サイクルで「わかった!」に変えます。
        </p>

        <div className="c-cycle-step s-course-high-school__cycle-step">
          <div className="c-cycle-figure">
            <p className="c-cycle-figure__caption">(苦手克服サイクル)</p>
            <img className="c-cycle-figure__image" src={CoursePointCycleImage} alt="" width="255" height="236" decoding="async" loading="lazy" />
          </div>

          <img className="c-cycle-step__arrow" src={CoursePointArrowImage} alt="" width={100} height={100} />

          <p className="c-cycle-step__step2 c-course-text">
            <span className="is-strong">苦手克服サイクルをくり返すことで苦手がなくなり</span>
            基礎学力が身につく!
          </p>

          <img className="c-cycle-step__arrow" src={CoursePointArrowImage} alt="" width={100} height={100} />

          <div className="c-cycle-step__step3">
            <p className="c-course-text">
              <span className="is-strong">2学期の学校の授業がよくわかる</span>
              ようになり、
            </p>

            <picture className="c-cycle-step__result">
              <source media="(min-width: 768px)" srcSet={CourseKouPhotoPCImage} width="711.5" height="266.25" />
              <img className="c-high-photo" src={CourseKouPhotoSPImage} alt="" width="256" height="263.505" decoding="async" loading="lazy" />
            </picture>
          </div>
        </div>

        <div className="c-point-head s-course-high-school__point2-head">
          <p className="c-point-head__badge">Point 2</p>
          <h5 className="c-point-head__text">つまずかないサポート</h5>
        </div>

        <img className="c-high-course-point2-img" src={CourseHighPoint2Image} alt="" width="230" height="159" decoding="async" loading="lazy" />

        <p className="c-course-text">
          指導経験豊富な担任が、勉強のお悩みをヒアリングしながら講習の提案や学習アドバイスを行います。自分に合う勉強法が分かるから
          <span className="is-strong">勉強のやる気アップにつながります！</span>
        </p>

        <div className="c-online-top">
          <div className="c-online-top__image">
            <img src={IllustrationFusenImage} alt="" width="41" height="30.55" decoding="async" loading="lazy" />
          </div>
        </div>

        <h6 className="c-sub-head" data-type="online">
          <span className="c-sub-head__deco" data-type="left"></span>
          <span className="c-sub-head__text">オンライン自習室</span>
          <span className="c-sub-head__deco" data-type="right"></span>
        </h6>

        <img className="c-online-image" src={CourseOnlinePhotoImage} alt="" width="280" height="175" decoding="async" loading="lazy" />

        <p className="c-course-text">
          授業以外の時間は、自習室を使って勉強することができます。
          <span className="is-strong">勉強を頑張る仲間が一緒だから、集中して取り組むことができます。</span>
        </p>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseHighSchoolPropsClassName>`
  border-top: ${rem(2)} solid #000;
  background-color: #d1efff;
  padding: ${rem(70 / 2)} ${rem(40 / 2)} ${rem(80 / 2)};

  @media ${breakpoints.lg} {
    border-top-width: ${rem(3)};
    padding-top: ${rem(86)};
    padding-bottom: ${rem(80)};
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* s-course-high-school */
  &.s-course-high-school {
  }

  .s-course-high-school__point-box {
    margin-top: ${rem(48)};
  }

  .s-course-high-school__point1-head {
    margin-top: ${rem(46)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(40)};
    }
  }

  .s-course-high-school__point2-head {
    margin-top: ${rem(60)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(120)};
    }
  }

  .s-course-high-school__cycle-step {
    margin-top: ${rem(16)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(42)};
    }
  }

  .s-course-high-school__img {
    width: ${rem(302 / 2)};
    margin: ${rem(40 / 2)} auto 0;
    display: block;

    @media ${breakpoints.lg} {
      width: ${rem(262)};
      margin-top: ${rem(49.2)};
    }
  }

  .c-high-course-img {
    width: ${rem(302 / 2)};
    display: block;
    margin: ${rem(40 / 2)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(273)};
      margin-top: ${rem(48)};
    }
  }

  .c-high-course-point2-img {
    width: ${rem(460 / 2)};
    display: block;
    margin: ${rem(40 / 2)} auto ${rem(10)};

    @media ${breakpoints.lg} {
      width: ${rem(368)};
      margin-bottom: ${rem(30)};
    }
  }

  .c-high-photo {
    display: block;
    width: ${rem(256)};
    margin: ${rem(16)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(640)};
      margin-top: ${rem(2)};
    }
  }

  .c-online-image {
    width: ${rem(560 / 2)};
    display: block;
    margin: ${rem(40 / 2)} auto ${rem(10)};

    @media ${breakpoints.lg} {
      width: ${rem(448)};
      margin-top: ${rem(38)};
      margin-bottom: ${rem(30)};
    }
  }
`

export const CourseHighSchool: (props: CourseHighSchoolProps) => JSX.Element = StyledComponent

export default CourseHighSchool
