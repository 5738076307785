import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints, innerWidth, innerPadding, innerDummyPadding, summerColor } from 'variables/_index'
import { useInView } from 'react-intersection-observer'

// 画像
import CoursePrimaryImage from 'assets/images/summer/course/primary/course-primary-img.webp'
import IllustrationBookImage from 'assets/images/summer/course/ill_book.webp'
import IllustrationPenImage from 'assets/images/summer/course/ill_pen.webp'
import CourseShoPhotoImage from 'assets/images/summer/course/primary/course_sho_photo.webp'
import classNames from 'classnames'

export interface CoursePrimaryProps {
  readonly noProps?: string
}

type CoursePrimaryPropsClassName = CoursePrimaryProps & {
  readonly className: string
}

function Component({ className }: CoursePrimaryPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={classNames(className, 's-course-primary-point1')}>
      <h3 className="c-course-content-head">小学生コース</h3>
      <img className="c-course-img" src={CoursePrimaryImage} width="142.18" height="92.5" alt="" decoding="async" loading="lazy" />
      <div className="c-point-box s-course-primary-point1__point-box">
        <h4 ref={ref} className="c-point-box__head" data-anim={inView}>
          <img className="c-point-box__illust1" src={IllustrationBookImage} alt="" width="71.89" height="67.305" decoding="async" loading="lazy" />
          <span className="is-color">
            <span className="is-large">2</span>つ
          </span>
          のカリキュラム
          <img className="c-point-box__illust2" src={IllustrationPenImage} alt="" width="67.305" height="61.475" decoding="async" loading="lazy" />
        </h4>

        <ul className="c-course-points">
          <li className="c-course-point">
            <span className="is-number">1.</span>集団WEB授業
          </li>
          <li className="c-course-point">
            <span className="is-number">2.</span>わくわく理科実験講座
          </li>
        </ul>

        <div className="c-point-head s-course-primary-point1__point1-head">
          <p className="c-point-head__badge">Point 1</p>
          <h4 className="c-point-head__text">
            <span className="is-small">中学で必要な学力が身につく</span>
            <br />
            集団WEB授業
          </h4>
        </div>

        <div className="c-primary-point1-row">
          <img className="c-primary-point1-image" src={CourseShoPhotoImage} alt="" width="280" height="175" decoding="async" loading="lazy" />
          <p className="c-course-text">
            <span className="is-strong">中学で学ぶ単元の土台となる基礎を復習し、定着させます。</span>
            発言や質問をしながら進める対面式授業だから、自宅でも集中して楽しく勉強ができます。
          </p>
        </div>

        <div className="c-primary-point-boxes s-course-primary-point1__point-boxes">
          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">対象</span>
            </h5>
            <p className="c-primary-point-text">小学4年生～小学6年生</p>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">日程</span>
            </h5>

            <ul className="c-course-curriculums-list">
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学4年生</span>
                <span className="c-course-curriculums-list__item-contents">7/22(月)～7/26(金)の5日間</span>
              </li>
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学5年生・小学6年生</span>
                <span className="c-course-curriculums-list__item-contents">7/22(月)～7/27(土)の6日間</span>
              </li>
            </ul>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">時間</span>
            </h5>
            <p className="c-primary-point-text">19:00~20:30（1コマ40分/1日2コマ）</p>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">教科</span>
            </h5>

            <ul className="c-course-curriculums-list is-three">
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学4年生</span>
                <span className="c-course-curriculums-list__item-contents">算数・国語</span>
              </li>
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学5年生</span>
                <span className="c-course-curriculums-list__item-contents">算数・国語・理科・社会</span>
              </li>
              <li className="c-course-curriculums-list__item">
                <span className="c-course-curriculums-list__item-head">小学6年生</span>
                <span className="c-course-curriculums-list__item-contents">算数・国語・英語・理科・社会</span>
              </li>
            </ul>
          </div>

          <div className="c-primary-point-box">
            <h5 className="c-primary-point-head">
              <span className="c-primary-point-head__text">料金</span>
            </h5>

            <ul className="c-course-curriculums-list">
              <li className="c-course-curriculums-list__item" data-type="price">
                <span className="c-course-curriculums-list__item-head">小学4年生</span>
                <span className="c-course-curriculums-list__item-contents" data-type="price">
                  受講料　｜<span className="is-bold">11,000円</span>
                </span>
                <span className="c-course-curriculums-list__item-contents" data-type="price">
                  テキスト｜<span className="is-bold">1,370円</span>
                </span>
              </li>
              <li className="c-course-curriculums-list__item" data-type="price">
                <span className="c-course-curriculums-list__item-head">小学5年生・小学6年生</span>
                <span className="c-course-curriculums-list__item-contents" data-type="price">
                  受講料　｜<span className="is-bold">15,400円</span>
                </span>
                <span className="c-course-curriculums-list__item-contents" data-type="price">
                  テキスト｜<span className="is-bold">3,205円</span>
                </span>
              </li>
            </ul>

            <p className="c-primary-point-box__note">&#8251;表示価格は税込みです。</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CoursePrimaryPropsClassName>`
  border-top: ${rem(2)} solid #000;
  background-color: #d1efff;
  padding: ${rem(70 / 2)} ${rem(40 / 2)} ${rem(80 / 2)};

  @media ${breakpoints.lg} {
    border-top-width: ${rem(3)};
    padding-top: ${rem(86)};
    padding-bottom: ${rem(80)};
  }

  &.s-course-primary-point1 {
  }

  .s-course-primary-point1__point-box {
    margin-top: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(20)};
    }
  }

  .s-course-primary-point1__point1-head {
    margin-top: ${rem(46)};
  }

  .s-course-primary-point1__point-boxes {
    margin-top: ${rem(36)};

    @media ${breakpoints.lg} {
    }
  }

  .c-primary-point1-image {
    display: block;
    width: ${rem(560 / 2)};
    margin: 0 auto ${rem(10)};
  }

  .c-primary-point1-row {
    @media ${breakpoints.lg} {
      display: flex;
      width: ${rem(842)};
      margin-left: auto;
      margin-right: auto;
      gap: ${rem(40)};

      > * {
        flex: 1;
      }
    }
  }

  .c-course-head {
    text-align: center;
    border-top: ${rem(2)} solid #000;
    border-bottom: ${rem(2)} solid #000;
    width: ${rem(370 / 2)};
    margin: ${rem(70 / 2)} auto 0 auto;
    padding: ${rem(10 / 2)} 0;
    font-size: ${rem(58 / 2)};
    font-family: ${fontFamily.zenKaku};

    @media ${breakpoints.lg} {
      width: ${rem(293)};
      margin: ${rem(80)} auto 0 auto;
      padding: ${rem(10)} 0;
      border-top: ${rem(3)} solid #000;
      border-bottom: ${rem(3)} solid #000;
      font-size: ${rem(42)};
    }
  }

  .c-course-img {
    display: block;
    width: ${rem(302 / 2)};
    margin: ${rem(38.5 / 2)} auto 0;

    @media ${breakpoints.lg} {
      width: ${rem(273)};
      margin: ${rem(49.2)} auto 0 auto;
    }
  }

  .c-course-curriculum-list {
    display: flex;
    flex-direction: column;
    gap: ${rem(30 / 2)};
    list-style: none;
    width: ${rem(560 / 2)};
    margin: ${rem(50 / 2)} auto 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(900)};
      margin: ${rem(44)} auto 0 auto;
      flex-direction: row;
      gap: ${rem(40)};
    }
  }

  .c-course-curriculum-list-item {
    font-size: ${rem(36 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    line-height: calc(68 / 36);
    margin: 0;
    padding: ${rem(11 / 2)} ${rem(33 / 2)};
    border-radius: ${rem(10 / 2)};
    background-color: #fff0f0;

    @media ${breakpoints.lg} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: ${rem(24)};
      border-radius: ${rem(10)};
    }
  }

  .c-course-curriculum-list-item-number {
    font-size: ${rem(52 / 2)};
    font-family: ${fontFamily.zenKakuA};
    font-weight: 600;
    line-height: calc(68 / 52);
    color: #ff1a56;
    margin-right: ${rem(7 / 2)};

    @media ${breakpoints.lg} {
      font-size: ${rem(42)};
      margin-right: ${rem(5 / 8)};
    }
  }

  .c-course-curriculum-head {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(42 / 2)};
    text-align: center;
    color: #0028be;
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: ${rem(6)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
    }

    &[data-anim='true'] {
      > .c-course-curriculum-illust1 {
        animation: slideFadeIn 1s 0s forwards;
      }

      > .c-course-curriculum-illust2 {
        animation: slideFadeIn 1s 0s forwards;
      }
    }

    > .c-course-curriculum-head-highlight {
      font-size: ${rem(54 / 2)};
      color: #ff1a56;
      letter-spacing: 0.06em;

      @media ${breakpoints.lg} {
        font-size: ${rem(42)};
      }

      > .c-course-curriculum-head-big {
        font-family: ${fontFamily.zenKakuA};
        font-size: ${rem(68 / 2)};
        letter-spacing: 0.06em;

        @media ${breakpoints.lg} {
          font-size: ${rem(54)};
        }
      }
    }
    > .c-course-curriculum-head-middle {
      font-size: ${rem(54 / 2)};
      letter-spacing: 0.06em;

      @media ${breakpoints.lg} {
        font-size: ${rem(42)};
      }
    }

    > .c-course-curriculum-illust1 {
      position: absolute;
      width: ${rem(130 / 2)};
      top: ${rem(-80 / 2)};
      left: ${rem(-60 / 2)};
      opacity: 0;
      transform: translateY(${rem(20)});

      @media ${breakpoints.lg} {
        width: ${rem(100)};
        top: ${rem(-25)};
        left: ${rem(-128)};
      }
    }

    > .c-course-curriculum-illust2 {
      position: absolute;
      width: ${rem(112.62 / 2)};
      right: ${rem(-50 / 2)};
      bottom: ${rem(50 / 2)};
      opacity: 0;
      transform: translateY(${rem(20)});

      @media ${breakpoints.lg} {
        width: ${rem(90.1)};
        right: ${rem(-120)};
        bottom: ${rem(15)};
      }
    }
  }

  .e-course-curriculum {
    background-color: #fff;
    border-radius: ${rem(20 / 2)};
    width: ${rem(670 / 2)};
    margin: ${rem(20 / 2)} auto 0 auto;
    padding: ${rem(47 / 2)} 0 ${rem(60 / 2)} 0;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
      margin: ${rem(20)} auto 0 auto;
      padding: ${rem(38.9)} 0 ${rem(57)} 0;
    }

    > .e-course-curriculum-item-head {
      position: relative;
      width: ${rem(620 / 2)};
      margin: ${rem(100 / 2)} auto 0 auto;
      text-align: center;
      color: #0028be;
      background-color: #fff0f0;
      font-size: ${rem(42 / 2)};
      padding: ${rem(40 / 2)} 0 ${rem(30 / 2)} 0;
      line-height: calc(58 / 42);
      font-family: ${fontFamily.zenKaku};
      border-top: solid #000 ${rem(2)};
      border-bottom: solid #000 ${rem(2)};

      @media ${breakpoints.lg} {
        width: ${rem(900)};
        margin: ${rem(89)} auto 0 auto;
        font-size: ${rem(32)};
        padding: ${rem(27)} 0 ${rem(20)} 0;
        border-top: solid #000 ${rem(3)};
        border-bottom: solid #000 ${rem(3)};
      }

      > .is-small {
        font-size: ${rem(36 / 2)};
        line-height: calc(58 / 36);

        @media ${breakpoints.lg} {
          font-size: ${rem(28)};
          line-height: calc(32 / 24);
        }
      }

      &[data-type='curriculum2'] {
        margin-top: ${rem(150 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(150)};
        }
      }

      > .e-course-curriculum-item-head-badge {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: ${rem(-40 / 2)};
        left: ${rem(-43 / 2)};
        width: ${rem(108 / 2)};
        height: ${rem(108 / 2)};
        font-family: ${fontFamily.zenKakuA};
        color: #fff;
        background-color: #ff648c;
        border-radius: 9999px;

        @media ${breakpoints.lg} {
          top: ${rem(-19)};
          left: ${rem(199)};
          width: ${rem(76)};
          height: ${rem(76)};
          font-size: ${rem(18)};
        }

        &[data-type='primary1'] {
          @media ${breakpoints.lg} {
            top: ${rem(-19)};
            left: ${rem(168)};
          }
        }

        &[data-type='primary2'] {
          @media ${breakpoints.lg} {
            top: ${rem(-19)};
            left: ${rem(208)};
          }
        }

        > .e-course-curriculum-item-head-number {
          display: block;
          font-size: ${rem(52 / 2)};
          line-height: 1;

          @media ${breakpoints.lg} {
            font-size: ${rem(36)};
          }
        }
      }
    }
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .c-primary-point-box__note {
    list-style: none;
    margin: ${rem(22 / 2)} auto 0;
    width: ${rem(590 / 2)};
    font-size: ${rem(22 / 2)};
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      width: ${rem(820)};
      margin-top: ${rem(18)};
      font-size: ${rem(14)};
    }
  }
`

export const CoursePrimary: (props: CoursePrimaryProps) => JSX.Element = StyledComponent

export default CoursePrimary
