import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily, summerColor } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'
import TeacherCard from 'components/section/summer/atom/teacherCard'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/css'

// 画像
import AboutPhoto1Img from 'assets/images/summer/about/about-photo1.webp'
import AboutPhoto2Img from 'assets/images/summer/about/about-photo2.webp'
import CompasImg from 'assets/images/summer/about/ill_compas.webp'
import NoterulerImg from 'assets/images/summer/about/ill_noteruler.webp'

import ArrowNextImageSP from 'assets/images/summer/course/teacher/slide-right.svg'
import ArrowPrevImageSP from 'assets/images/summer/course/teacher/slide-left.svg'
import ArrowNextImagePC from 'assets/images/summer/course/teacher/slide-right.svg'
import ArrowPrevImagePC from 'assets/images/summer/course/teacher/slide-left.svg'
import Teacher01Img from 'assets/images/summer/about/teacher01.webp'
import Teacher02Img from 'assets/images/summer/about/teacher02.webp'
import Teacher03Img from 'assets/images/summer/about/teacher03.webp'
import Teacher04Img from 'assets/images/summer/about/teacher04.webp'
import Teacher05Img from 'assets/images/summer/about/teacher05.webp'

export interface AboutSectionProps {
  readonly noProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-120px', // ref要素が現れてから120px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: movieRef, inView: movieInView } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  const splideRef = useRef<Splide>(null)
  const splideCurrentRef = useRef<HTMLSpanElement>(null)
  const splideTotalRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    if (splideRef.current && splideRef.current.splide && splideTotalRef.current) {
      splideTotalRef.current.textContent = splideRef.current.splide.length.toString()
      splideRef.current.splide.on('active', function () {
        if (splideRef.current && splideRef.current.splide && splideCurrentRef.current) {
          splideCurrentRef.current.textContent = (splideRef.current.splide.index + 1).toString()
        }
      })
    }
  })

  return (
    <div className={className}>
      <div className="l-inner">
        <p className="c-about-lead">オンライン夏期講習</p>
        <h2 className="c-about-head" ref={ref} data-anim={inView}>
          <span className="c-about-head__dp">
            <StaticImage src="../../../assets/images/summer/head-dp.png" alt="" />
          </span>
          <span className="c-about-head__background">点数アップできる</span>
          <span className="c-about-head__background">
            <span className="u-font-zen-kaku-a">2</span>つの理由
          </span>
          <span className="c-about-head__arrow">
            <StaticImage src="../../../assets/images/summer/head-arrow.png" alt="" />
          </span>
        </h2>

        <div className="c-head-bubble is-first" data-number="first">
          <p className="c-head-bubble__text">
            理由<span className="u-font-zen-kaku-a">1</span>
          </p>
        </div>
        <h3 className="c-head-title">
          “わかる”が増える
          <br />
          <span className="is-big">総復習</span>
        </h3>

        <div className="c-about-box">
          <div className="c-about-box__contents">
            <img className="c-about-box__image1" src={AboutPhoto1Img} alt="" width={420} height={371} decoding="async" loading="lazy" />

            <p className="c-about-box__text">
              夏期講習では、今までに学習した範囲を総復習！苦手を克服し”わかる”を増やすことで、
              <span className="is-strong">基礎学力が身につきます</span>。その結果、
              <span className="is-strong">2学期の学校の授業が理解しやすくなり、テスト点数がアップ！</span>
            </p>
          </div>
        </div>

        <div className="c-head-bubble is-second">
          <p className="c-head-bubble__text">
            理由<span className="u-font-zen-kaku-a">2</span>
          </p>
        </div>
        <h3 className="c-head-title">
          先生が直接教える
          <br />
          <span className="is-big">オンライン個別指導</span>
        </h3>

        <div className="c-about-box">
          <div className="c-about-box__contents">
            <img className="c-about-box__image2" src={AboutPhoto2Img} alt="" width={420} height={371} decoding="async" loading="lazy" />
            <ul className="c-about-box__lists">
              <li className="c-about-box__list">一人ひとりの理解度に合わせた指導</li>
              <li className="c-about-box__list">その場で先生に質問できる</li>
              <li className="c-about-box__list">苦手な問題は、わかるまで丁寧に解説</li>
            </ul>
          </div>

          <div className="c-about-box__teacher-area c-teacher">
            <p className="c-teacher__head">私たちが指導します</p>
            <p className="c-teacher__lead">
              わかりやすくて楽しい授業を
              <br className="is-pc-hidden" />
              行う講師を、一部紹介！
            </p>
            <div className="c-teacher__slide">
              <Splide
                ref={splideRef}
                options={{
                  autoplay: true, // 自動再生を有効
                  interval: 3000, // 自動再生の間隔を3秒に設定
                  type: 'loop', // ループ再生を有効
                  perPage: 2, // 1回のスライドで表示する枚数
                  speed: 800, // スライドのアニメーション時間を0.8秒に設定
                  perMove: 1, // 1枚ずつ移動
                  focus: 0, // ページネーションを移動の枚数に合わせる
                  gap: '1.875rem', // スライド間の余白を設定
                  pagination: false,
                  breakpoints: {
                    740: {
                      perMove: 1,
                      perPage: 1,
                      padding: '2.4rem',
                      gap: '1.375rem',
                    },
                  },
                }}
              >
                <SplideSlide>
                  <TeacherCard
                    title="尾下先生"
                    imageElement={<img src={Teacher01Img} alt="" width={260} height={260} decoding="async" loading="lazy" />}
                    position="早稲田大学在籍"
                    text={<>「できた！勉強って面白い！」と思えるような楽しくわかりやすい授業を心がけています。</>}
                  />
                </SplideSlide>
                <SplideSlide>
                  <TeacherCard
                    title="野崎先生"
                    imageElement={<img src={Teacher02Img} alt="" width={260} height={260} decoding="async" loading="lazy" />}
                    position="九州工業大学在籍"
                    text={<>生徒にはやる気が出る言葉やポジティブになる言葉をかけ、常に明るく元気に指導をしています。</>}
                  />
                </SplideSlide>
                <SplideSlide>
                  <TeacherCard
                    title="瀬尾先生"
                    imageElement={<img src={Teacher03Img} alt="" width={260} height={260} decoding="async" loading="lazy" />}
                    position="神戸市外国語大学在籍"
                    text={<>分からない部分を根本から無くし、勉強を楽しいと思ってもらえるよう指導しています！</>}
                  />
                </SplideSlide>
                <SplideSlide>
                  <TeacherCard
                    title="今井先生"
                    imageElement={<img src={Teacher04Img} alt="" width={260} height={260} decoding="async" loading="lazy" />}
                    position="九州大学在籍"
                    text={<>生徒一人ひとりの理解度に合わせた授業で成績アップをサポートします。</>}
                  />
                </SplideSlide>
                <SplideSlide>
                  <TeacherCard
                    title="田中先生"
                    imageElement={<img src={Teacher05Img} alt="" width={260} height={260} decoding="async" loading="lazy" />}
                    position="九州大学大学院在籍"
                    text={<>生徒一人ひとりに寄り添い、質問しやすい雰囲気づくりを心掛けています！</>}
                  />
                </SplideSlide>
              </Splide>
              <p className="c-splide-number-pagination">
                <span className="c-splide-number-pagination__current" ref={splideCurrentRef}>
                  1
                </span>
                /
                <span className="c-splide-number-pagination__total" ref={splideTotalRef}>
                  10
                </span>
              </p>
            </div>
          </div>

          <div className="c-about-box__movie-area c-movie">
            <p className="c-movie__head">実際の授業の様子</p>
            <div className="c-movie__area" ref={movieRef} data-anim={movieInView}>
              <div className="c-movie__illust1">
                <img src={CompasImg} alt="" width={100.63} height={90.09} decoding="async" loading="lazy" />
              </div>
              <iframe
                className="c-movie__iframe"
                src="https://www.youtube-nocookie.com/embed/BNdjdVvzivg?si=JdwjoDCBNSiltfUt"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <div className="c-movie__illust2">
                <img src={NoterulerImg} alt="" width={148.73} height={196.18} decoding="async" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  background-color: #ddf3ff;
  padding-top: ${rem(70 / 2)};
  padding-bottom: ${rem(80 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(58)};
    padding-bottom: ${rem(80)};
  }

  .l-inner {
    width: ${rem(670 / 2)};
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
    }
  }

  .c-about-lead {
    margin: 0 0 0;
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(46 / 2)};
    text-align: center;
    color: #0028be;

    @media ${breakpoints.lg} {
      font-size: ${rem(38)};
    }
  }

  .c-about-head {
    width: fit-content;
    margin: ${rem(3)} auto 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${rem(10 / 2)};
    align-items: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    font-size: ${rem(62 / 2)};
    color: #0028be;

    @media ${breakpoints.lg} {
      margin-top: ${rem(10)};
      gap: ${rem(10)};
      font-size: ${rem(52)};
    }

    &[data-anim='true'] {
      .c-about-head__background,
      .c-about-head__dp {
        animation: slideUp 0.8s 0s forwards;
      }

      .c-about-head__arrow {
        animation: arrowSlide 1.5s 0.4s forwards;
      }
    }

    @keyframes slideUp {
      0% {
        transform: translateY(${rem(25)});
        opacity: 0;
      }
      100% {
        transform: translateY(${rem(0)});
        opacity: 1;
      }
    }

    .c-about-head__background {
      width: fit-content;
      display: block;
      background-color: #ffffff;
      border-radius: ${rem(10)};
      padding: ${rem(8 / 2)} ${rem(20 / 2)};
      z-index: 1;
      transform: translateY(${rem(25)});
      opacity: 0;

      @media ${breakpoints.lg} {
        padding: ${rem(4)} ${rem(20)};
      }
    }

    .c-about-head__dp {
      position: absolute;
      left: ${rem(-28 / 2)};
      top: ${rem(-10 / 2)};
      width: ${rem(40 / 2)};
      z-index: 2;
      transform: translateY(${rem(25)});
      opacity: 0;

      @media ${breakpoints.lg} {
        left: ${rem(-20)};
        top: ${rem(-10)};
        width: ${rem(34)};
      }
    }

    .c-about-head__arrow {
      position: absolute;
      width: ${rem(200)};
      right: ${rem(-65)};
      top: ${rem(10)};
      rotate: -45deg;
      opacity: 0;
      transform: translateX(${rem(-50)});

      @media ${breakpoints.lg} {
        width: ${rem(330)};
        right: ${rem(-100)};
        top: ${rem(15)};
      }

      @keyframes arrowSlide {
        0% {
          opacity: 0;

          transform: translateX(${rem(-50)});
        }
        100% {
          transform: translateX(${rem(0)});
          opacity: 1;
        }
      }
    }
  }

  .c-about-box {
    margin: ${rem(20 / 2)} auto 0 auto;
    padding: ${rem(20)} ${rem(22)} ${rem(26)};
    background-color: #ffffff;
    border: #00c8ff solid ${rem(2)};
    border-radius: ${rem(20 / 2)};

    @media ${breakpoints.lg} {
      padding: ${rem(38)} ${rem(100)} ${rem(38)};
      border-width: ${rem(3)};
      margin-top: ${rem(26)};
      border-radius: ${rem(20)};
    }
  }

  .c-about-box__contents {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media ${breakpoints.lg} {
      flex-direction: row-reverse;
      align-items: center;
      gap: ${rem(36)};
    }
  }

  .c-about-box__image1 {
    display: block;
    width: ${rem(420 / 2)};
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(336)};
      flex-shrink: 0;
    }
  }

  .c-about-box__image2 {
    width: ${rem(490 / 2)};
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(392)};
      flex-shrink: 0;
    }
  }

  .c-about-box__text {
    margin: ${rem(2)} 0 0;
    font-size: ${rem(32 / 2)};
    letter-spacing: 0.04em;
    line-height: calc(58 / 32);

    .is-strong {
      color: #0093ff;
      font-weight: 700;
    }

    @media ${breakpoints.lg} {
      margin-top: 0;
      font-size: ${rem(18)};
      letter-spacing: 0.06em;
      line-height: calc(32 / 18);
    }
  }

  .c-about-box__lists {
    list-style: none;
    margin: ${rem(40 / 2)} 0 0;
    padding: 0;
    font-size: ${rem(32 / 2)};
    letter-spacing: 0.04em;
    line-height: calc(58 / 32);

    @media ${breakpoints.lg} {
      margin-top: 0;
      font-size: ${rem(20)};
      letter-spacing: 0.06em;
    }
  }

  .c-about-box__list {
    position: relative;
    padding: 0 0 0 ${rem(14)};
    margin: 0;

    &::before {
      content: '';
      width: ${rem(14 / 2)};
      height: ${rem(14 / 2)};
      border-radius: 50%;
      background-color: ${summerColor.fitMain};
      position: absolute;
      top: ${rem(15)};
      left: 0;

      @media ${breakpoints.lg} {
        width: ${rem(10)};
        height: ${rem(10)};
        top: ${rem(15)};
      }
    }

    @media ${breakpoints.lg} {
      padding-left: ${rem(16)};
      line-height: 2;
    }
  }

  .c-about-box__teacher-area {
    margin-top: ${rem(40)};
    margin-inline: ${rem(-22)};

    @media ${breakpoints.lg} {
      width: 100vw;
      margin-inline: calc(50% - 50vw);
      margin-top: ${rem(58)};
    }
  }

  .c-head-bubble {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${rem(250 / 2)};
    height: ${rem(80 / 2)};
    margin: 0 auto;
    background-color: #ffe000;
    border-radius: 9999px;
    color: #0028be;

    @media ${breakpoints.lg} {
      width: ${rem(200)};
      height: ${rem(64.25)};
    }

    &.is-first {
      margin-top: ${rem(42)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(96)};
      }
    }

    &.is-second {
      margin-top: ${rem(100 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
      }
    }

    &::after {
      position: absolute;
      top: 100%;
      display: block;
      content: '';
      height: calc(${rem(43 / 2)} / 2 * tan(30deg));
      width: ${rem(43 / 2)};
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
      background-color: #ffe000;
      transform: rotate(180deg);

      @media ${breakpoints.lg} {
        height: calc(${rem(37.5)} / 2 * tan(35deg));
        width: ${rem(37.5)};
      }
    }
  }

  .c-head-bubble__text {
    text-align: center;
    font-size: ${rem(32 / 2)};
    color: #0028be;
    margin: 0;
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;

    @media ${breakpoints.lg} {
      font-size: ${rem(26)};
    }
  }

  .c-head-title {
    text-align: center;
    font-size: ${rem(42 / 2)};
    color: #0028be;
    font-weight: bold;
    font-family: ${fontFamily.zenKaku};
    margin: ${rem(43 / 2)} auto 0;
    letter-spacing: 0.06em;

    @media ${breakpoints.lg} {
      font-size: ${rem(32)};
      margin-top: ${rem(38)};
    }

    > .is-big {
      display: block;
      margin-top: ${rem(16 / 2)};
      font-size: ${rem(54 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(12)};
        font-size: ${rem(42)};
      }
    }
  }

  .c-movie {
    padding: 0;
    margin-top: ${rem(82 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(60)};
    }

    .c-movie__head {
      margin: 0;
      text-align: center;
      font-size: ${rem(32 / 2)};
      font-weight: bold;
      font-family: ${fontFamily.zenKaku};
      letter-spacing: 0.05em;

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
      }
    }

    .c-movie__area {
      position: relative;
      max-width: ${rem(560 / 2)};
      margin: ${rem(20 / 2)} auto 0 auto;

      @media ${breakpoints.lg} {
        max-width: ${rem(500)};
        margin: ${rem(17)} auto 0 auto;
      }

      .c-movie__iframe {
        border: none;
        width: 100%;
        margin: 0;
        aspect-ratio: 560 / 350;
      }

      &[data-anim='true'] {
        .c-movie__illust1 {
          animation: slideFadeIn 1s 0s forwards;
        }

        .c-movie__illust2 {
          animation: slideFadeIn 1s 0s forwards;
        }
      }

      .c-movie__illust1 {
        display: none;

        @media ${breakpoints.lg} {
          width: ${rem(100)};
          display: block;
          position: absolute;
          top: ${rem(120)};
          left: ${rem(-154)};
          opacity: 0;
          transform: translateY(${rem(20)});
        }

        img {
          margin: 0;
        }
      }

      .c-movie__illust2 {
        display: none;

        @media ${breakpoints.lg} {
          width: ${rem(148.73)};
          display: block;
          position: absolute;
          top: ${rem(50)};
          right: ${rem(-180)};
          opacity: 0;
          transform: translateY(${rem(20)});
        }

        img {
          margin: 0;
        }
      }
    }
  }

  .u-font-zen-kaku-a {
    font-family: ${fontFamily.zenKakuA};
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .c-teacher {
  }

  .c-teacher__head {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(38 / 2)};
    letter-spacing: 0.06em;
    text-align: center;
    color: #0028be;
    gap: ${rem(10)};

    @media ${breakpoints.lg} {
      font-size: ${rem(30)};
      gap: ${rem(24)};
    }

    &::before,
    &::after {
      content: '';
      width: ${rem(3 / 2)};
      height: ${rem(40 / 2)};
      background: #000;

      @media ${breakpoints.lg} {
        width: ${rem(3)};
        height: ${rem(32)};
      }
    }

    &::before {
      rotate: -20deg;
    }
    &::after {
      rotate: 20deg;
    }
  }

  .c-teacher__lead {
    margin: ${rem(10)} 0 0;
    font-size: ${rem(32 / 2)};
    font-weight: 700;
    line-height: 1.6;
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(22)};
      font-size: ${rem(24)};
    }

    .is-pc-hidden {
      @media ${breakpoints.lg} {
        display: none;
      }
    }
  }

  .c-teacher__slide {
    margin-top: ${rem(10)};
    padding-top: ${rem(25 / 2)};
    overflow: hidden;
    position: relative;

    @media ${breakpoints.lg} {
      width: ${rem(950)};
      margin-inline: auto;
      padding-inline: ${rem(30)};
      padding-top: ${rem(20)};
      margin-top: ${rem(30)};
    }

    .swiper,
    .splide {
      overflow: visible;
      padding-bottom: ${rem(16)};

      @media ${breakpoints.lg} {
        padding-bottom: ${rem(30)};
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: ${rem(30)};
        height: calc(100% + ${rem(10)});
        background: #fff;
        bottom: 0;
        z-index: 2;

        @media ${breakpoints.lg} {
        }
      }

      &::before {
        left: 100%;
      }

      &::after {
        right: 100%;
      }
    }

    .splide__track {
      overflow: visible;
    }

    .swiper-slide,
    .splide__slide {
      height: auto;

      > [class^='teacherCard'] {
        height: 100%;
      }
    }

    .swiper-button-prev,
    .swiper-button-next,
    .splide__arrow--prev,
    .splide__arrow--next {
      width: ${rem(90 / 2)};
      height: ${rem(90 / 2)};
      margin-top: ${rem(-18)};
      z-index: 3;
      opacity: 1;

      @media ${breakpoints.lg} {
        width: ${rem(60)};
        height: ${rem(60)};
        margin-top: ${rem(-42)};
      }

      svg {
        display: none;
      }
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      display: none;
    }

    .swiper-button-prev,
    .splide__arrow--prev {
      background-image: url(${ArrowPrevImageSP});
      background-repeat: no-repeat;
      background-size: contain;
      left: auto;
      right: calc(50% + ${rem(224 / 2)}); // calc(140 / 375 * 100vw)

      @media ${breakpoints.lg} {
        /* background-image: url(${ArrowPrevImagePC}); */
        left: ${rem(-30)};
        right: auto;
      }
    }

    .swiper-button-next,
    .splide__arrow--next {
      background-image: url(${ArrowNextImageSP});
      background-repeat: no-repeat;
      background-size: contain;
      right: auto;
      left: calc(50% + ${rem(224 / 2)}); // calc(140 / 375 * 100vw)

      @media ${breakpoints.lg} {
        /* background-image: url(${ArrowNextImagePC}); */
        left: auto;
        right: ${rem(-30)};
      }
    }

    .swiper-pagination,
    .splide__pagination {
      bottom: 0;
      justify-content: center;
      font-size: ${rem(28 / 2)};
      letter-spacing: 0.06em;
      font-weight: 700;
      color: #7e7e7e;
      padding: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(16)};
      }
    }

    .swiper-pagination-bullet,
    .splide__pagination__page {
      width: ${rem(12)};
      height: ${rem(12)};
      background-color: #e2e2e2;
      opacity: 1;
      margin-top: 0;
      margin-right: 0 !important;
      margin-left: 0 !important;

      @media ${breakpoints.lg} {
      }

      &.swiper-pagination-bullet-active,
      &.is-active {
        background-color: blue;
        transform: scale(1);
      }
    }
  }

  .c-splide-number-pagination {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 0.5em;
    margin: 0;
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    font-weight: 700;
    color: #7e7e7e;

    @media ${breakpoints.lg} {
      font-size: ${rem(16)};
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element = StyledComponent

export default AboutSection
