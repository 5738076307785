import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, color, fontFamily } from 'variables/_index'
import { Link } from 'gatsby'
import { ArrowRightKu } from 'components/icon'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

export interface CourseButtonProps {
  readonly href: string
}

type CourseButtonPropsClassName = CourseButtonProps & {
  readonly className: string
}

function Component({ className, href }: CourseButtonPropsClassName) {
  return (
    <a href={href} className={classNames(className, 'c-course-link')}>
      日程や料金はこちら
      <span className="c-course-link__arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.93" height="25.4" viewBox="0 0 14.93 25.4">
          <path
            id="course_btn_arrow"
            d="M15.049,10.609a2.158,2.158,0,0,1,3.041,0L28.717,21.274a2.149,2.149,0,0,1,.067,2.964L18.313,34.747a2.147,2.147,0,1,1-3.041-3.031l8.9-9.038-9.123-9.038A2.126,2.126,0,0,1,15.049,10.609Z"
            transform="translate(-14.414 -9.982)"
            fill="#0028be"
          />
        </svg>
      </span>
    </a>
  )
}

const StyledComponent = styled(Component)<CourseButtonPropsClassName>`
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(540 / 2)};
  margin: 0 auto;
  text-decoration: none;
  font-family: ${fontFamily.zenKaku};
  font-weight: bold;
  padding: ${rem(28 / 2)} 0;
  color: #0028be;
  background-color: #fff;
  border-radius: 9999px;
  border: solid#B4D4E8 ${rem(8 / 2)};
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.06em;

  @media ${breakpoints.lg} {
    width: ${rem(446)};
    padding: ${rem(22)} 0;
    border: solid#B4D4E8 ${rem(6)};
    font-size: ${rem(24)};
  }

  &:hover {
    color: #fff;
    background-color: #00c8ff;

    .c-course-link__arrow {
      svg {
        stroke: white;

        path {
          fill: white;
        }
      }
    }
  }

  .c-course-link__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: ${rem(35 / 2)};
    width: ${rem(14.93 / 2)};
    height: ${rem(25.4 / 2)};

    @media ${breakpoints.lg} {
      right: ${rem(28)};
      width: ${rem(11.94)};
      height: ${rem(20.32)};
    }

    svg {
      width: 100%;
      transition: all 0.3s ease-in-out;

      path {
        transition: all 0.3s ease-in-out;
      }
    }
  }
`

export const CourseButton: (props: CourseButtonProps) => JSX.Element = StyledComponent

export default CourseButton
