import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, summerColor } from 'variables/_index'
import ContactButton from 'components/section/summer/atom/contactButton'
import { siteStructure } from 'utils/site'
import classNames from 'classnames'

// 画像
import cpRibbonImage from 'assets/images/summer/contact/cp_ribbon.svg'
import cpTenImage from 'assets/images/summer/contact/cp_ten.svg'
import cpEraserImage from 'assets/images/summer/contact/cp_eraser.webp'
import cpFudebakoImage from 'assets/images/summer/contact/cp_fudebako.webp'
import cpNotepenImage from 'assets/images/summer/contact/cp_notepen.webp'

export interface ContactCampaignSectionProps {
  readonly type: 'elementary' | 'junior' | 'high'
}

type ContactCampaignSectionPropsClassName = ContactCampaignSectionProps & {
  readonly className: string
}

function Component({ className }: ContactCampaignSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="c-campaign-box">
        <p className="c-campaign-box__line1">夏限定</p>
        <p className="c-campaign-box__line2">特別キャンペーン</p>
        <img className="c-campaign-box__image1" src={cpFudebakoImage} alt="" width={65.6} height={57.76} decoding="async" loading="lazy" />
        <img className="c-campaign-box__image2" src={cpEraserImage} alt="" width={34.65} height={27.24} decoding="async" loading="lazy" />
        <img className="c-campaign-box__image3" src={cpNotepenImage} alt="" width={131.67} height={105.76} decoding="async" loading="lazy" />
        <ul className="c-campaign-box__labels">
          <li className="c-campaign-box__label is-campaign1">
            <span className="is-marker">
              <span className="is-40">5</span>日間
            </span>
            の講習授業を
            <br />
            <span className="is-marker">
              <span className="is-34">無料</span>で<span className="is-34">体験</span>
            </span>
            できる!
          </li>
          <li className="c-campaign-box__label is-campaign2">
            講習受講後の入会で
            <br />
            <span className="is-marker">
              入会金<span className="is-40">22,000</span>円
            </span>
            を<span className="is-marker is-34">全額免除</span>
          </li>
        </ul>
      </div>
      <div className={classNames('e-contact-area')}>
        <div className="e-inner">
          <div className="e-image"></div>

          <div className="e-buttons">
            <div className="e-button">
              <ContactButton href={siteStructure.summerContact.path} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactCampaignSectionPropsClassName>`
  font-weight: 700;
  text-align: center;
  position: relative;
  background-color: #ffe000;
  margin-top: ${rem(62)};
  padding-top: ${rem(338)};
  padding-bottom: ${rem(60 / 2)};

  @media ${breakpoints.lg} {
    margin-top: ${rem(100)};
    padding-top: ${rem(362)};
    padding-bottom: ${rem(50)};
  }

  .c-campaign-box {
    position: absolute;
    top: ${rem(-26)};
    inset-inline: 0;
    margin-inline: auto;
    max-width: ${rem(345)};
    background: #0093ff;
    padding: ${rem(34)} ${rem(18)} ${rem(24)};

    @media ${breakpoints.lg} {
      top: ${rem(-40)};
      max-width: ${rem(1000)};
      padding: ${rem(54)} ${rem(38)} ${rem(42)};
    }

    &::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      width: ${rem(8)};
      height: ${rem(26)};
      background: #0028be;
      clip-path: polygon(0 0, 0 100%, 100% 100%);

      @media ${breakpoints.lg} {
        width: ${rem(12)};
        height: ${rem(40)};
      }
    }
  }

  .c-campaign-box__line1 {
    color: #fff;
    font-family: ${fontFamily.zenKaku};
    font-size: ${rem(48 / 2)};
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1;
    text-align: center;
    margin: 0;
    position: relative;
    width: fit-content;
    margin-inline: auto;

    @media ${breakpoints.lg} {
      font-size: ${rem(38)};
    }

    &::before {
      content: '';
      position: absolute;
      top: ${rem(-23)};
      left: ${rem(-21)};
      width: ${rem(80.56 / 2)};
      height: ${rem(59.06 / 2)};
      background: url(${cpTenImage}) no-repeat center center / contain;

      @media ${breakpoints.lg} {
        top: ${rem(-36)};
        left: ${rem(-32)};
        width: ${rem(64.45)};
        height: ${rem(47.25)};
      }
    }
  }

  .c-campaign-box__line2 {
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    font-size: ${rem(58 / 2)};
    letter-spacing: 0.04em;
    text-align: center;
    color: #fff040;
    line-height: 1;
    margin: ${rem(6)} 0 0;

    @media ${breakpoints.lg} {
      font-size: ${rem(46)};
      margin-top: ${rem(6)};
    }
  }

  .c-campaign-box__image1,
  .c-campaign-box__image2,
  .c-campaign-box__image3 {
    position: absolute;
    margin: 0;
  }

  .c-campaign-box__image1 {
    top: ${rem(28 / 2)};
    right: calc(50% + ${rem(225 / 2)});
    width: ${rem(82 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(22)};
      right: calc(50% + ${rem(190)});
      width: ${rem(65.6)};
    }
  }

  .c-campaign-box__image2 {
    top: ${rem(38 / 2)};
    right: calc(50% + ${rem(182 / 2)});
    width: ${rem(43.31 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(32)};
      right: calc(50% + ${rem(152)});
      width: ${rem(34.65)};
    }
  }

  .c-campaign-box__image3 {
    top: ${rem(26 / 2)};
    left: calc(50% + ${rem(156 / 2)});
    width: ${rem(164.59 / 2)};

    @media ${breakpoints.lg} {
      top: ${rem(18)};
      left: calc(50% + ${rem(120)});
      width: ${rem(131.67)};
    }
  }

  .c-campaign-box__labels {
    display: flex;
    flex-direction: column;
    gap: ${rem(15)};
    padding: 0;
    margin: ${rem(18)} 0 0;

    @media ${breakpoints.lg} {
      flex-direction: row;
      margin-top: ${rem(30)};
      gap: ${rem(25)};
    }
  }

  .c-campaign-box__label {
    font-family: ${fontFamily.zenKaku};
    font-weight: bold;
    font-size: ${rem(42 / 2)};
    line-height: 1.28;
    text-align: center;
    color: #003393;
    background: #fff;
    border-radius: ${rem(0)} ${rem(20 / 2)} ${rem(20 / 2)} ${rem(20 / 2)};
    box-shadow: 0 ${rem(2.5)} 0 0 #003393;
    margin: 0;
    padding: ${rem(8)} ${rem(0)} ${rem(12)};
    height: ${rem(135)};
    overflow: hidden;
    flex: 1;
    position: relative;

    &.is-campaign1 {
      padding-top: ${rem(8)};
      letter-spacing: 0.05em;

      @media ${breakpoints.lg} {
        padding-top: ${rem(10)};
      }
    }

    &.is-campaign2 {
      padding-top: ${rem(16)};

      @media ${breakpoints.lg} {
        padding-top: ${rem(24)};
      }
    }

    @media ${breakpoints.lg} {
      border-radius: ${rem(0)} ${rem(20)} ${rem(20)} ${rem(20)};
      padding: ${rem(16)} ${rem(10)} ${rem(10)};
      box-shadow: 0 ${rem(4)} 0 0 #003393;
      font-size: ${rem(28)};
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: ${rem(25)};
      height: ${rem(25)};
      background: url(${cpRibbonImage}) no-repeat center center / contain;

      @media ${breakpoints.lg} {
        width: ${rem(37.4)};
        height: ${rem(37.4)};
      }
    }

    .is-34 {
      font-size: ${rem(52 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(34)};
      }
    }

    .is-40 {
      font-size: ${rem(58 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(40)};
      }
    }

    .is-marker {
      background-image: linear-gradient(to top, #fff040 ${rem(40 / 2)}, transparent ${rem(40 / 2)}, transparent 100%);

      @media ${breakpoints.lg} {
        background-image: linear-gradient(to top, #fff040 ${rem(28)}, transparent ${rem(28)}, transparent 100%);
      }
    }
  }

  > .e-buttons {
    margin-top: ${rem(10)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(6)};
    }

    > .e-button {
      text-align: center;

      &:nth-child(n + 2) {
        margin-top: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(16)};
        }
      }
    }
  }
`

export const ContactCampaignSection: (props: ContactCampaignSectionProps) => JSX.Element = StyledComponent

export default ContactCampaignSection
