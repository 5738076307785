import React, { useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerSpringWidth, fontFamily, summerColor } from 'variables/_index'
import { useInView } from 'react-intersection-observer'

// 画像
import HeadingWaveImage from 'assets/images/summer/heading-wave.svg'
import FlowTopImg from 'assets/images/summer/flow/flow_top_img.webp'
import FlowArrowImg from 'assets/images/summer/flow/flow_arrow.svg'
import FlowMinusImg from 'assets/images/summer/flow/flow-minus.webp'
import FlowPlusImg from 'assets/images/summer/flow/flow-plus.webp'
import FlowStep3Img from 'assets/images/summer/flow/flow_step3.webp'
import FlowDottedImg from 'assets/images/summer/flow/flow-dotted.svg'

export interface FlowSectionProps {
  readonly noProps?: string
}

type FlowSectionPropsClassName = FlowSectionProps & {
  readonly className: string
}

function Component({ className }: FlowSectionPropsClassName) {
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = () => {
    setOpen(!open)
  }

  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <h2 className="e-flow-head">
        <span className="e-flow-head-jp">
          <span className="is-small">オンライン夏期講習</span>
          <span className="is-normal">受講までの流れ</span>
        </span>
        <span className="e-flow-head-en">flow</span>
      </h2>

      <div ref={ref} className="e-flow-content" data-anim={inView}>
        <div className="e-inner">
          <div className="e-flow-image">
            <img src={FlowTopImg} alt="" width={550} height={388} decoding="async" loading="lazy" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">1</span>
              <span className="e-flow-text">無料体験のお申し込み</span>
            </h3>
          </div>
          <div className="e-flow-arrow">
            <img src={FlowArrowImg} alt="" width={64} height={38} decoding="async" loading="lazy" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">2</span>
              <span className="e-flow-text">体験ガイダンス日時の設定</span>
            </h3>
          </div>
          <div className="e-flow-arrow">
            <img src={FlowArrowImg} alt="" width={64} height={38} decoding="async" loading="lazy" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">3</span>
              <span className="e-flow-text">
                体験ガイダンス<small>（スマホで参加OK!）</small>
              </span>
            </h3>

            <button type="button" onClick={toggleOpen} className="e-flow-open">
              {open ? (
                <img src={FlowMinusImg} alt="" width={36} height={36} decoding="async" loading="lazy" />
              ) : (
                <img src={FlowPlusImg} alt="" width={36} height={36} decoding="async" loading="lazy" />
              )}
            </button>

            <div className="e-flow-boxContent3" data-open={open}>
              <div className="e-flow-box-image">
                <img src={FlowStep3Img} alt="" width={400} height={250} decoding="async" loading="lazy" />
              </div>
              <div className="e-flow-boxTexts">
                <div className="e-flow-boxText c-text-box">
                  <p className="c-text-box__text">
                    お子さまの学習状況や
                    <br className="u-hidden-pc" />
                    お悩みをヒアリング
                  </p>
                </div>
                <div className="e-flow-boxLine">
                  <img src={FlowDottedImg} alt="" width={4} height={3} decoding="async" loading="lazy" />
                </div>
                <div className="e-flow-boxText c-text-box">
                  <p className="c-text-box__text">
                    Fitのサービスや講習についての
                    <br className="u-hidden-pc" />
                    詳しいご説明と質疑応答
                  </p>
                </div>
                <div className="e-flow-boxLine">
                  <img src={FlowDottedImg} alt="" width={4} height={3} decoding="async" loading="lazy" />
                </div>
                <div className="c-text-box is-large">
                  <div className="c-text-box__head">講習のお申し込み</div>
                  <p className="c-text-box__text">
                    担当者と一緒に、お子さまに
                    <br className="u-hidden-pc" />
                    適切な受講プランを決めます。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="e-flow-arrow">
            <img src={FlowArrowImg} alt="" width={64} height={38} decoding="async" loading="lazy" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">4</span>
              <span className="e-flow-text">夏期講習スタート!</span>
            </h3>
            <div className="e-flow-campaign">
              中学生・高校生コースは
              <br />
              夏期講習<span className="is-large48 is-color">5</span>
              <span className="is-color">日間</span>を<span className="is-large42 is-color">無料で体験！</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<FlowSectionPropsClassName>`
  margin-left: auto;
  margin-right: auto;
  padding-top: ${rem(55)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(86)};
  }

  .c-text-box {
    background-color: #f2f2f2;
    text-align: center;
    border-radius: ${rem(20 / 2)};
    padding: ${rem(20 / 2)} ${rem(40 / 2)};

    @media ${breakpoints.lg} {
      border-radius: ${rem(60)};
      padding: ${rem(10)} ${rem(30)};
    }

    &.is-large {
      padding-top: ${rem(10)};
      padding-bottom: ${rem(34 / 2)};

      @media ${breakpoints.lg} {
        padding-top: ${rem(12)};
        padding-bottom: ${rem(12)};
      }
    }

    .c-text-box__head {
      color: #0093ff;
      font-size: ${rem(32 / 2)};
      text-align: center;
      letter-spacing: 0.06em;
      margin-bottom: ${rem(10 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
        margin-bottom: ${rem(2)};
      }
    }

    .c-text-box__text {
      font-size: ${rem(28 / 2)};
      line-height: 1.42;
      letter-spacing: 0.1em;
      margin: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }
    }
  }

  > .e-flow-head {
    text-align: center;
    display: flex;
    flex-direction: column;

    > .e-flow-head-jp {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: ${rem(68 / 2)};
      font-family: ${fontFamily.zenKaku};
      color: #0028be;
      letter-spacing: 0.04em;

      @media ${breakpoints.lg} {
        font-size: ${rem(54)};
        font-weight: bold;
      }

      .is-small {
        font-size: ${rem(48 / 2)};
        margin-bottom: ${rem(8)};

        @media ${breakpoints.lg} {
          font-size: ${rem(38)};
        }
      }

      &::after {
        display: block;
        content: '';
        width: ${rem(120.12 / 2)};
        height: ${rem(12.55 / 2)};
        background: url(${HeadingWaveImage}) no-repeat center / contain;
        margin-top: ${rem(15 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(96.8)};
          height: ${rem(10.75)};
          margin-top: ${rem(14)};
        }
      }
    }

    > .e-flow-head-en {
      font-family: ${fontFamily.zenKakuA};
      color: #acacac;
      font-size: ${rem(38 / 2)};
      margin-top: ${rem(15 / 2)};
      font-weight: 700;
      letter-spacing: 0.2em;

      @media ${breakpoints.lg} {
        margin-top: ${rem(14)};
        font-size: ${rem(30)};
      }
    }
  }

  > .e-flow-content {
    background-color: ${summerColor.mainBackground};
    margin: ${rem(22)} 0 0 0;
    padding: ${rem(60 / 2)} 0 ${rem(30)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(36)};
      padding-top: ${rem(60)};
      padding-bottom: ${rem(60)};
    }

    &[data-anim='true'] {
      > .e-inner {
        > .e-flow-image {
          animation: slideFadeIn 1s 0s forwards;
        }
      }
    }

    > .e-inner {
      width: calc(100% - ${rem(40)});
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${rem(1080)};
        padding-left: ${rem(40)};
        padding-right: ${rem(40)};
      }

      > .e-flow-image {
        width: ${rem(364 / 2)};
        margin-bottom: ${rem(25)};
        margin-left: auto;
        margin-right: auto;
        opacity: 0;
        transform: translateY(${rem(20)});

        @media ${breakpoints.lg} {
          width: ${rem(264)};
          margin-bottom: ${rem(32)};
        }

        img {
          margin: 0 auto;
          max-width: 100%;
        }
      }

      > .e-flow-box {
        position: relative;
        margin-left: ${rem(-10 / 2)};
        margin-right: ${rem(-10 / 2)};
        border: 2px solid ${summerColor.fitMain};
        border-radius: ${rem(20 / 2)};
        background-color: #fff;

        @media ${breakpoints.lg} {
          width: ${rem(800)};
          margin: 0 auto;
          border-radius: ${rem(20)};
          border-width: 3px;
        }

        &.is-wide {
          padding-top: ${rem(24 / 2)};

          @media ${breakpoints.lg} {
            padding-top: ${rem(12)};
          }
        }

        > .e-flow-title {
          position: relative;
          color: ${summerColor.text};
          font-weight: bold;
          font-family: ${fontFamily.zenKaku};
          display: flex;
          align-items: center;
          padding: 0 ${rem(24 / 2)} 0 ${rem(20)};
          margin: 0;
          min-height: ${rem(100 / 2)};

          @media ${breakpoints.lg} {
            padding: 0 ${rem(27.8)};
            min-height: ${rem(74)};
          }

          > .e-flow-number {
            font-family: ${fontFamily.zenKakuA};
            font-size: ${rem(64 / 2)};
            color: ${summerColor.fitMain};
            position: relative;

            @media ${breakpoints.lg} {
              font-size: ${rem(44)};
            }
          }

          > .e-flow-text {
            margin-left: ${rem(16 / 2)};
            font-size: ${rem(38 / 2)};
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              margin-left: ${rem(20)};
              font-size: ${rem(30)};
            }

            > small {
              font-size: ${rem(24 / 2)};
              letter-spacing: 0;
              font-weight: normal;

              @media ${breakpoints.lg} {
                font-size: ${rem(20)};
              }
            }
          }
        }

        > .e-flow-open {
          position: absolute;
          width: ${rem(45 / 2)};
          top: ${rem(29 / 2)};
          right: ${rem(14 / 2)};
          border: none;
          cursor: pointer;
          outline: none;
          padding: 0;
          appearance: none;
          background-color: transparent;

          @media ${breakpoints.lg} {
            width: ${rem(36)};
            top: ${rem(20)};
            right: ${rem(20)};
          }

          img {
            margin: 0;
          }
        }

        > .e-flow-boxContent3 {
          margin: ${rem(3)} auto ${rem(40 / 2)};
          padding: 0 ${rem(60 / 2)};
          transition: 0.5s;
          overflow: hidden;

          @media ${breakpoints.lg} {
            margin-top: ${rem(3)};
            margin-bottom: ${rem(50)};
            padding: 0 ${rem(100)};
          }

          &[data-open='false'] {
            max-height: 0;
            margin: 0;
          }

          > .e-flow-box-image {
            text-align: center;

            img {
              width: ${rem(550 / 2)};
              margin: 0;

              @media ${breakpoints.lg} {
                width: ${rem(400)};
              }
            }
          }

          > .e-flow-boxTexts {
            margin-top: ${rem(18)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(30)};
            }

            > .e-flow-boxText {
            }
          }

          .e-flow-boxLine {
            text-align: center;

            img {
              display: block;
              width: ${rem(4 / 2)};
              margin: 0 auto;

              @media ${breakpoints.lg} {
                width: ${rem(4)};
              }
            }
          }
        }
      }

      > .e-flow-arrow {
        width: ${rem(63.554 / 2)};
        height: ${rem(37.373 / 2)};
        margin: ${rem(28.5 / 2)} auto ${rem(24.1 / 2)} auto;

        @media ${breakpoints.lg} {
          width: ${rem(63.554)};
          height: ${rem(37.373)};
          margin-top: ${rem(18.6)};
          margin-bottom: ${rem(13)};
        }

        img {
          margin: 0;
        }
      }
    }

    @keyframes slideFadeIn {
      0% {
        opacity: 0;
        transform: translateY(${rem(20)});
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .e-flow-campaign {
    width: ${rem(560 / 2)};
    margin: ${rem(4)} auto ${rem(16)};
    border-radius: ${rem(20 / 2)};
    background: #ffee71;
    padding: ${rem(20 / 2)};
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    font-size: ${rem(32 / 2)};
    letter-spacing: 0.04em;
    line-height: calc(54 / 42);
    text-align: center;

    @media ${breakpoints.lg} {
      width: ${rem(600)};
      font-size: ${rem(24)};
      border-radius: ${rem(20)};
      padding: ${rem(18)};
      margin-top: ${rem(8)};
      margin-bottom: ${rem(36)};
    }

    .is-large42 {
      font-size: ${rem(42 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(32)};
      }
    }

    .is-large48 {
      font-size: ${rem(48 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(38)};
      }
    }

    .is-color {
      color: #0093ff;
    }
  }
`

export const FlowSection: (props: FlowSectionProps) => JSX.Element = StyledComponent

export default FlowSection
